<template>
  <div
    :class="['rich-text-editor', {
      'is-disabled': props.readOnly,
    }]"
  >
    <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
    <div class="label">
      {{ props.label }}
      <p
        v-if="props.subLabel"
        class="help"
      >
        {{ props.subLabel }}
      </p>
      <p style="font-weight: normal">
        <QuillEditor
          :id="props.inputId"
          ref="quillEditor"
          class="quill-editor-limited-height"
          :theme="props.theme"
          content-type="html"
          :options="editorOptions"
          @keyup="onTextChange"
        />
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  defineProps,
  defineEmits,
  watch,
} from 'vue';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

/*
  Props
*/
const props = defineProps({
  label: {
    type: String,
    required: false,
    default: '',
  },
  subLabel: {
    type: String,
    required: false,
    default: '',
  },
  theme: {
    type: String,
    required: false,
    default: 'snow',
  },
  isSimple: {
    type: Boolean,
    required: false,
    default: false,
  },
  readOnly: {
    type: Boolean,
    required: false,
    default: true,
  },
  modelValue: {
    type: String,
    required: true,
  },
  inputId: {
    type: String,
    required: true,
  },
  inputPlaceholder: {
    type: String,
    required: false,
    default: '',
  },
});

const emit = defineEmits([
  'update:modelValue',
]);

/*
  Data
*/
const quillEditor = ref(null);
const toolbarContent = ref([
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ size: ['small', false, 'large', 'huge'] }],
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote', 'code-block'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ align: [] }],
  [{ color: [] }, { background: [] }],
  ['link'], // ['link', 'image', 'video'],
  // [{ header: 1 }, { header: 2 }],
  // [{ script: 'sub' }, { script: 'super' }],
  // [{ direction: 'rtl' }],
  // [{ font: [] }],
  // ['clean'],
]);
const editorOptions = ref({
  theme: 'bubble',
  contentType: 'html',
  // debug: 'info',
  // enable: props.enable,
  placeholder: props.readOnly ? '' : props.inputPlaceholder,
  readOnly: props.readOnly,
  modules: {
    toolbar: props.isSimple ? 'minimal' : toolbarContent.value,
  },
});

const onTextChange = () => {
  emit('update:modelValue', quillEditor.value.getHTML());
};

watch(props, (newValue) => {
  if (quillEditor.value.getHTML() === props.modelValue) {
    return;
  }
  quillEditor.value.setContents(props.modelValue, 'html');
  editorOptions.value.readOnly = newValue.readOnly;
});
</script>

<style>
.ql-editor {
  min-height: 200px;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  box-shadow: inset 0 0.0625em 0.125em rgba(10,10,10,.05);
}
.ql-container.ql-snow, .ql-toolbar.ql-snow {
  border-radius: 8px;
  border: none;
}
.rich-text-editor.is-disabled .ql-editor{
  padding: 0 !important;
  width: -webkit-fill-available;
}
.rich-text-editor p {
  margin-bottom: 0.5rem !important;
}
.quill-editor-limited-height .ql-editor {
  max-height: 200px;
  overflow-y: auto;
}
</style>

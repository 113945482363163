import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, unref as _unref, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "navbar-brand" }
const _hoisted_2 = { class: "navbar-item" }
const _hoisted_3 = { class: "buttons" }

import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import AuthStore from '@/store/auth';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';
import SmallLogoComponent from '@/components/shared/SmallLogoComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeHeader',
  setup(__props) {

const router = useRouter();
const { isAuthenticated } = AuthStore();

/*
  Data
*/
const isActive = ref(false);
const isLoading = ref(false);
const topMenu = ref('');

/*
  Methods
*/
const goHome = () => {
  router.push({
    name: 'HomePage',
  });
  // window.open('/', '_self');
};
// const closeMenu = () => {
//   isActive.value = false;
// };
const toggleMenu = () => {
  isActive.value = !isActive.value;
};

/*
  Hooks
*/
onMounted(() => {
  // topMenu.value.addEventListener('click', closeMenu);
});


return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", {
      ref_key: "topMenu",
      ref: topMenu,
      class: "navbar is-fixed-top is-white has-background-white",
      role: "navigation",
      "aria-label": "main navigation"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_RouterLink, {
          to: { name: 'HomePage' },
          class: "navbar-item",
          "aria-label": "navbar main logo",
          onClick: goHome
        }, {
          default: _withCtx(() => [
            _createVNode(SmallLogoComponent)
          ]),
          _: 1
        }),
        _createElementVNode("a", {
          href: "#top",
          class: _normalizeClass(["navbar-burger", { 'is-active': isActive.value }]),
          "aria-label": "Toggle Menu",
          onClick: toggleMenu,
          onKeyup: _withKeys(toggleMenu, ["enter"])
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("span", { "aria-hidden": "true" }, null, -1),
          _createElementVNode("span", { "aria-hidden": "true" }, null, -1),
          _createElementVNode("span", { "aria-hidden": "true" }, null, -1)
        ]), 34)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(['navbar-menu', { 'is-active': isActive.value }])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(['navbar-end', { 'is-hidden': _unref(isAuthenticated)() }])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_RouterLink, {
                class: "button is-primary",
                to: {
                name: 'SessionSignupPage'
              }
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" Criar minha conta ")
                ])),
                _: 1
              }),
              _createVNode(_component_RouterLink, {
                class: "button is-light",
                to: {
                name: 'SessionLoginPage'
              }
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode(" Login ")
                ])),
                _: 1
              })
            ])
          ])
        ], 2)
      ], 2)
    ], 512),
    (isLoading.value)
      ? (_openBlock(), _createBlock(LoadingComponent, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "column is-full p-0" }

import {
  ref,
  computed,
  onBeforeMount,
} from 'vue';
import { useRouter } from 'vue-router';
import { useCookies } from 'vue3-cookies';
import useStreamStore from '@/store/stream';
import useAuthStore from '@/store/auth';
import Stream from '@/models/stream';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BroadcastWatchLayout',
  setup(__props) {

const router = useRouter();
const {
  streamDoGet,
  streamsDoGetRealtimeTokens,
} = useStreamStore();
const {
  session,
} = useAuthStore();

/*
  Data
*/
const userRole = ref(2); // 1 = host, 2 = audience
const isLoading = ref(true);
const myStream = ref({} as Stream);
const rtt = ref(null);

/*
  Computed
*/
const streamUri = computed(() => router.currentRoute.value.params.uri);

/*
  Methods
*/
const getUserName = (() => {
  if (session.name) {
    return session.name;
  }
  const { cookies } = useCookies();
  return cookies.get('_ll_uname');
});
const redirectDashboard = ((error?: string) => router.push({
  name: 'DashboardPage',
  query: {
    message: 'Não foi possível carregar sua live. Tente novamente em instantes.',
    detail: error,
    type: 'error',
  },
}));
const reloadStream = async () => {
  const res = await streamDoGet(streamUri.value);

  if (res === undefined) {
    redirectDashboard('A live que você tentou acessar não está disponível ou não existe.');
    return;
  }

  myStream.value = res;
  console.log('myStream.value', myStream.value);

  if (res.status === 'finished') {
    await router.push({
      name: 'StreamLandingFinishedPage',
      params: {
        uri: streamUri.value,
      },
    });
    return;
  }

  if (res.status === 'pending') {
    await router.push({
      name: 'StreamLandingCountdownPage',
      params: {
        uri: streamUri.value,
      },
    });
    return;
  }

  if (res.status === 'live') {
    isLoading.value = false;
  }
};
const load = async () => {
  isLoading.value = true;
  await reloadStream();
  rtt.value = await streamsDoGetRealtimeTokens(
    myStream.value.uuid,
    userRole.value,
  );
  isLoading.value = false;
};

/*
  Hooks
*/
onBeforeMount(() => {
  load();
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (myStream.value && rtt.value)
          ? (_openBlock(), _createBlock(_component_RouterView, {
              key: 0,
              stream: myStream.value,
              rtt: rtt.value,
              "user-name": getUserName(),
              "onUpdate:myStream": load,
              "onReload:stream": reloadStream
            }, null, 8, ["stream", "rtt", "user-name"]))
          : _createCommentVNode("", true)
      ])
    ]),
    (isLoading.value)
      ? (_openBlock(), _createBlock(LoadingComponent, {
          key: 0,
          "full-screen": true
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, unref as _unref, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "navbar-brand" }
const _hoisted_2 = { class: "navbar-start" }
const _hoisted_3 = { class: "buttons" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "navbar-item" }
const _hoisted_7 = { class: "buttons" }

import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import AuthStore from '@/store/auth';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';
import SmallLogoComponent from '@/components/shared/SmallLogoComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'MerchantHeader',
  setup(__props) {

const router = useRouter();
const {
  isAuthenticated,
  toggleStoreMode,
} = AuthStore();

/*
  Data
*/
const isActive = ref(false);
const isLoading = ref(false);
const submitDisabled = ref(false);
const topMenu = ref('');

/*
  Methods
*/
const goHome = () => {
  router.push({
    name: 'DashboardPage',
  });
  // window.open('/', '_self');
};
const closeMenu = () => {
  isActive.value = false;
};
const myProfile = () => {
  // isLoading.value = true;
  router.push({
    name: 'UserProfileManagerPage',
  });
  // setTimeout(() => {
  //   isLoading.value = false;
  // }, 5000);
};
// const switchAccount = async () => {
//   router.push({ name: 'SessionSwitchAccountPage' });
// };
const toggleMenu = () => {
  isActive.value = !isActive.value;
};

/*
  Hooks
*/
onMounted(() => {
  // topMenu.value.addEventListener('click', closeMenu);
});


return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", {
      ref_key: "topMenu",
      ref: topMenu,
      class: "navbar is-fixed-top is-white has-background-white",
      role: "navigation",
      "aria-label": "main navigation"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_RouterLink, {
          to: { name: 'HomePage' },
          class: "navbar-item",
          "aria-label": "navbar main logo",
          onClick: goHome
        }, {
          default: _withCtx(() => [
            _createVNode(SmallLogoComponent)
          ]),
          _: 1
        }),
        _createElementVNode("a", {
          href: "#top",
          class: _normalizeClass(["navbar-burger", { 'is-active': isActive.value }]),
          "aria-label": "Toggle Menu",
          onClick: toggleMenu,
          onKeyup: _withKeys(toggleMenu, ["enter"])
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("span", { "aria-hidden": "true" }, null, -1),
          _createElementVNode("span", { "aria-hidden": "true" }, null, -1),
          _createElementVNode("span", { "aria-hidden": "true" }, null, -1)
        ]), 34)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(['navbar-menu', { 'is-active': isActive.value }])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_RouterLink, {
            class: _normalizeClass(['navbar-item', {
            'is-active': _unref(router).currentRoute.value.fullPath.includes('/dashboard')
          }]),
            to: {
            name: 'HomePage',
          },
            onClick: closeMenu
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" Página Inicial ")
            ])),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_RouterLink, {
            class: _normalizeClass(['navbar-item', {
            'is-active': _unref(router).currentRoute.value.fullPath.includes('/merchants/')
          }]),
            to: {
            name: 'MyMerchantsPage',
          },
            onClick: closeMenu
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode(" Lojas ")
            ])),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_RouterLink, {
            class: _normalizeClass(['navbar-item', {
            'is-active': _unref(router).currentRoute.value.fullPath.includes('/streams/')
          }]),
            to: {
            name: 'MyStreamsPage',
          },
            onClick: closeMenu
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode(" Lives ")
            ])),
            _: 1
          }, 8, ["class"])
        ]),
        _cache[10] || (_cache[10] = _createElementVNode("hr", null, null, -1)),
        _createElementVNode("div", {
          class: _normalizeClass(['navbar-end', { 'is-hidden': !_unref(isAuthenticated)() }])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(['navbar-item', {
            'is-active': _unref(router).currentRoute.value.fullPath.includes('/profile/')
          }])
          }, [
            _createElementVNode("div", _hoisted_3, [
              (_unref(router).currentRoute.value.fullPath.includes('/dashboard'))
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "button is-white",
                    type: "button",
                    disabled: submitDisabled.value,
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(toggleStoreMode) && _unref(toggleStoreMode)(...args)))
                  }, [
                    _createVNode(_component_font_awesome_icon, {
                      class: "fa-lg mr-2",
                      icon: { prefix: 'fa', iconName: 'toggle-on' }
                    }),
                    _cache[5] || (_cache[5] = _createElementVNode("span", null, " Modo Loja ", -1))
                  ], 8, _hoisted_4))
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                class: "button is-white",
                type: "button",
                disabled: submitDisabled.value,
                onClick: myProfile
              }, [
                _createVNode(_component_font_awesome_icon, {
                  class: "fa-lg mr-2",
                  icon: { prefix: 'fa', iconName: 'user-circle' }
                }),
                _cache[6] || (_cache[6] = _createTextVNode(" Minha conta "))
              ], 8, _hoisted_5)
            ])
          ], 2)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(['navbar-end', { 'is-hidden': _unref(isAuthenticated)() }])
        }, [
          _cache[9] || (_cache[9] = _createElementVNode("hr", null, null, -1)),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_RouterLink, {
                class: "button is-primary",
                to: {
                name: 'SessionSignupPage'
              }
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode(" Cadastro ")
                ])),
                _: 1
              }),
              _createVNode(_component_RouterLink, {
                class: "button is-light",
                to: {
                name: 'SessionLoginPage'
              }
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode(" Login ")
                ])),
                _: 1
              })
            ])
          ])
        ], 2)
      ], 2)
    ], 512),
    (isLoading.value)
      ? (_openBlock(), _createBlock(LoadingComponent, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})
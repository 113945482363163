<template>
  <RouterView
    :my-merchant="myMerchant"
    @update:my-merchant="resetFields"
  />
  <LoadingComponent
    v-if="isLoading"
    :full-screen="true"
  />
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  onBeforeMount,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';
import useMerchantStore from '@/store/merchant';
import Merchant from '@/models/merchant';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';

const router = useRouter();
const {
  getMyMerchant,
  merchantsDoGetMy,
  merchantStore,
} = useMerchantStore();

/*
  Data
*/
const myMerchant = ref({});
const isLoading = ref(true);
const uuid = ref('');

/*
  Computed
*/
const merchantUuid = computed(() => router.currentRoute.value.params.uuid);

/*
  Methods
*/
const redirectDashboard = ((error: string) => router.push({
  name: 'DashboardPage',
  query: {
    message: 'Não foi possível carregar sua loja. Tente novamente em instantes.',
    detail: error,
    type: 'error',
  },
}));

const resetFields = async (merchant?: Merchant) => {
  isLoading.value = true;
  if (merchant == null || merchant === undefined) {
    await merchantsDoGetMy()
      .then(async (response) => {
        if (response === undefined) {
          throw new Error('Não foi possível completar sua solicitação. Tente novamente mais tarde.');
        }
        const m = response.data.find((v: Merchant) => v.uuid === uuid.value);
        if (m === undefined || m.uuid === undefined) {
          redirectDashboard('Não foi possível completar sua solicitação. Verifique o link e tente novamente');
          return;
        }
        myMerchant.value = m;
      })
      .catch((error) => {
        redirectDashboard(error);
      });
  } else {
    myMerchant.value = merchant;
  }
  isLoading.value = false;
};

/*
  Hooks
*/
onBeforeMount(async () => {
  uuid.value = merchantUuid.value;
  resetFields();
});
watch(merchantStore, async () => {
  myMerchant.value = getMyMerchant(uuid.value);
}, { deep: true });
</script>

<style lang="scss" scoped>
</style>

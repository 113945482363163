import InstagramLiveMessageReply from '@/models/plugin/instagramLiveMessageReply';

class InstagramLiveMessage {
  id: string;

  mediaId: string;

  accountId: string;

  commentId: string;

  userId: string;

  userName: string;

  text: string;

  timestamp: Date;

  reply: InstagramLiveMessageReply | null = null;

  constructor({
    id,
    mediaId,
    accountId,
    commentId,
    userId,
    userName,
    text,
    timestamp,
    reply,
  }: {
    id: string;
    mediaId: string;
    accountId: string;
    commentId: string;
    userId: string;
    userName: string;
    text: string;
    timestamp: Date;
    reply: InstagramLiveMessageReply | null;
  }) {
    this.id = id;
    this.mediaId = mediaId;
    this.accountId = accountId;
    this.commentId = commentId;
    this.userId = userId;
    this.userName = userName;
    this.text = text;
    this.timestamp = timestamp;
    this.reply = reply;
  }

  static fromApiResponse(columns: string[], rows: any[][]): InstagramLiveMessage[] {
    if (rows.length === 0) {
      return [];
    }
    return rows.map((row: any[]) => {
      const data: any = {};
      columns.forEach((column, index) => {
        const camelCaseKey = column.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
        if (row[index] && (camelCaseKey === 'timestamp' || camelCaseKey === 'createdAt')) {
          data[camelCaseKey] = new Date(row[index]);
        } else if (camelCaseKey === 'reply') {
          data[camelCaseKey] = row[index] && typeof row[index] === 'object'
            ? InstagramLiveMessageReply.fromApiObject(row[index])
            : null;
        } else {
          data[camelCaseKey] = row[index];
        }
      });
      return new InstagramLiveMessage(data);
    });
  }
}

export default InstagramLiveMessage;

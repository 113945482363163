import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import {
  ref,
  computed,
  onBeforeMount,
} from 'vue';
import { useRouter } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';
import Stream from '@/models/stream';
import useStreamStore from '@/store/stream';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'StreamManagerProductsLayout',
  setup(__props) {

const router = useRouter();

const {
  streamsDoGetMy,
  streamsDoUpdateMyStream,
} = useStreamStore();

/*
  Data
*/
const myStream = ref({} as Stream);
const isLoading = ref(false);
const dataLoaded = ref(false);

/*
  Computed
*/
const streamUuid = computed(() => router.currentRoute.value.params.uuid);

/*
  Methods
*/
const load = async () => {
  isLoading.value = true;
  await streamsDoGetMy({
    uuid: streamUuid.value,
  }).then(async (response) => {
    if (response.data.length > 0) {
      [myStream.value] = response.data.filter((stream) => stream.uuid === streamUuid.value);
    }
  }).catch((error) => {
    console.error(error);
  });
  isLoading.value = false;
};
const updateStream = async (value) => {
  const { productsUuid, activeProductsId, showProductsOnLanding } = value;
  isLoading.value = true;
  const response = await streamsDoUpdateMyStream({
    uuid: streamUuid.value,
    productsUuid,
    activeProductsId,
    showProductsOnLanding,
  });
  if (response === undefined) {
    notify({
      duration: 5000,
      type: 'error',
      title: 'Ooops',
      text: 'Não foi possível completar sua solicitação. Tente novamente mais tarde.',
    });
  } else {
    load();
  }
  isLoading.value = false;
};

/*
  Hooks
*/
onBeforeMount(async () => {
  dataLoaded.value = false;
  await load();
  dataLoaded.value = true;
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_notifications = _resolveComponent("notifications")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (dataLoaded.value)
      ? (_openBlock(), _createBlock(_component_RouterView, {
          key: 0,
          stream: myStream.value,
          "is-loading": isLoading.value,
          "onUpdate:stream": _cache[0] || (_cache[0] = (value) => updateStream(value))
        }, null, 8, ["stream", "is-loading"]))
      : _createCommentVNode("", true),
    (isLoading.value)
      ? (_openBlock(), _createBlock(LoadingComponent, {
          key: 1,
          "full-screen": !dataLoaded.value
        }, null, 8, ["full-screen"]))
      : _createCommentVNode("", true),
    _createVNode(_component_notifications, { classes: "toast-notification" })
  ], 64))
}
}

})
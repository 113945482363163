<template>
  <PageHeader
    :is-loading="isLoading"
    title="Minha conta"
  />

  <main class="main has-navbar-fixed-top">
    <RouterView
      v-model="myProfile"
      @update:my-profile="resetFields"
    />
  </main>
  <LoadingComponent
    v-if="isLoading"
  />
</template>

<script setup lang="ts">
import {
  ref,
  onBeforeMount,
  onUnmounted,
} from 'vue';
import { useRouter } from 'vue-router';
import useUserStore from '@/store/user';
import PageHeader from '@/components/header/PageHeader.vue';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';

const router = useRouter();
const {
  usersDoGetMyUser,
  resetStore,
} = useUserStore();

/*
  Data
*/
const isLoading = ref(true);
const myProfile = ref({});

/*
  Methods
*/
const redirectDashboard = ((error: string) => router.push({
  name: 'DashboardPage',
  query: {
    message: 'Não foi possível carregar seu perfil. Tente novamente em instantes.',
    detail: error,
    type: 'error',
  },
}));
const resetFields = async () => {
  isLoading.value = true;
  await usersDoGetMyUser()
    .then((response) => {
      if (response === undefined) {
        redirectDashboard('O perfil que você tento acessar está indisponível no momento. Recarregue a página e tente novamente');
      }
      myProfile.value = response;
      isLoading.value = false;
    }).catch((error) => redirectDashboard(error.toString()));
};

/*
  Hooks
*/
onBeforeMount(async () => {
  await resetFields();
});
onUnmounted(() => {
  resetStore();
});
</script>

<style lang="scss" scoped>
</style>

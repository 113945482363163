import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import {
  ref,
  computed,
  onBeforeMount,
} from 'vue';
import { useRouter } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';
import Stream from '@/models/stream';
import Product from '@/models/externalLink/product';
import useStreamStore from '@/store/stream';
import useProductsStore from '@/store/externalLink/product';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'StreamManagerExternalProductsLayout',
  setup(__props) {

const router = useRouter();
const {
  productsDoSearch,
} = useProductsStore();
const {
  streamsDoGetMy,
  streamsDoUpdateMyStream,
} = useStreamStore();

/*
  Data
*/
const brandProducts = ref([]);
const products = ref([]);
const myStream = ref({} as Stream);
const isLoading = ref(true);

/*
  Computed
*/
const streamUuid = computed(() => router.currentRoute.value.params.uuid);

/*
  Methods
*/
const loadBrandProducts = async (stream: Stream) => {
  await productsDoSearch({ merchantUuid: stream.merchantUuid })
    .then((response) => {
      if (response !== undefined) {
        brandProducts.value = response.data;

        const sPHash = stream.productsUuid?.reduce((acc, cur) => {
          acc[cur] = true;
          return acc;
        }, {});

        if (sPHash === undefined) {
          return;
        }
        products.value = brandProducts.value.filter((p) => sPHash[p.uuid] !== undefined);
        products.value = products.value.map((p) => new Product(p));
      }
    }).catch((error) => {
      console.error(error);
    });
};
const load = async () => {
  isLoading.value = true;
  await streamsDoGetMy({ uuid: streamUuid.value })
    .then(async (response) => {
      if (response.data.length > 0) {
        [myStream.value] = response.data.filter((stream) => stream.uuid === streamUuid.value);
        await loadBrandProducts(myStream.value);
      }
    }).catch((error) => {
      console.error(error);
    }).finally(() => {
      isLoading.value = false;
    });
  isLoading.value = false;
};
const updateStream = async (value) => {
  const { productsUuid, activeProductsId, showProductsOnLanding } = value;
  isLoading.value = true;
  const response = await streamsDoUpdateMyStream({
    uuid: streamUuid.value,
    productsUuid,
    activeProductsId,
    showProductsOnLanding,
  });
  if (response === undefined) {
    notify({
      duration: 5000,
      type: 'error',
      title: 'Ooops',
      text: 'Não foi possível completar sua solicitação. Tente novamente mais tarde.',
    });
  } else {
    load();
  }
  isLoading.value = false;
};

/*
  Hooks
*/
onBeforeMount(async () => {
  load();
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_notifications = _resolveComponent("notifications")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_RouterView, {
      stream: myStream.value,
      "brand-products": brandProducts.value,
      products: products.value,
      "onUpdate:stream": _cache[0] || (_cache[0] = (value) => updateStream(value))
    }, null, 8, ["stream", "brand-products", "products"]),
    (isLoading.value)
      ? (_openBlock(), _createBlock(LoadingComponent, {
          key: 0,
          "full-screen": false
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_notifications, { classes: "toast-notification" })
  ], 64))
}
}

})
<!-- eslint-disable max-len -->
<template>
  <section
    id="top"
    class="hero is-white is-fullheight has-navbar-fixed-top"
  >
    <AppHeader />
    <div class="hero-body p-0">
      <div class="columns is-multiline is-vcentered m-0">
        <div class="column is-6 p-5">
          <div class="columns is-multiline">
            <div class="column is-full">
              <p class="title is-1 is-size-2-mobile">
                Maximize o impacto do video e multiplique sua taxa de conversão
              </p>
            </div>
            <div class="column is-full">
              <p class="subtitle is-4 is-size-4-mobile has-text-grey">
                Atinja conversões 10x superiores ou mais que seu e-commerce: Descubra o poder da transmissão ao vivo com a Live Link
              </p>
            </div>
            <div class="column is-full">
              <button
                type="button"
                :class="[
                  'button',
                  'is-primary',
                  'is-large',
                  'is-white',
                  'is-outline',
                  {
                    'is-loading': isLoading,
                  },
                ]"
                :disabled="isLoading"
                @click="signupNow"
              >
                Inscreva-se agora
              </button>
            </div>
          </div>
        </div>
        <div class="column is-6 p-0">
          <div class="column is-full">
            <img
              class="box p-0"
              style="border-radius: 0;border-top-left-radius: 2rem;border-bottom-left-radius: 2rem;"
              alt="Placeholder image"
              src="@/assets/images/smiley-woman-selling-clothes-side-view.jpg"
            >
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    id="channel"
    class="hero is-white is-fullheight pt-6"
  >
    <div class="columns is-multiline is-vcentered m-0">
      <div class="column is-full mb-6">
        <p class="title is-1 is-size-2-mobile has-text-centered">
          Tudo em um só lugar
        </p>
        <p class="subtitle is-3 is-size-4-mobile has-text-centered has-text-grey">
          Uma ferramenta completa para <span class="is-nowrap">você vender mais</span>
        </p>
      </div>
      <div class="column is-full">
        <ul class="columns is-multiline benefits">
          <li class="column is-4 border-radius is-flex">
            <div class="box">
              <p class="title is-3 is-size-4-mobile">
                Gestão<br>de lojas
              </p>
              <p class="subtitle is-5 is-size-5-mobile mt-2 has-text-grey">
                Gerencie múltiplas marcas em um único painel. Personalize e configure cada loja para refletir a identidade única da sua marca, proporcionando uma experiência excepcional para seus clientes.
              </p>
            </div>
          </li>
          <li class="column is-4 border-radius is-flex">
            <div class="box">
              <p class="title is-3 is-size-4-mobile">
                Transmissões<br>ao vivo
              </p>
              <p class="subtitle is-5 is-size-5-mobile mt-2 has-text-grey">
                Agende e realize lives impactantes. Apresente seus produtos em tempo real, interaja com seu público e maximize suas vendas com uma plataforma interativa e envolvente.
              </p>
            </div>
          </li>
          <li class="column is-4 border-radius is-flex">
            <div class="box">
              <p class="title is-3 is-size-4-mobile">
                Controle de<br>estoque inteligente
              </p>
              <p class="subtitle is-5 is-size-5-mobile mt-2 has-text-grey">
                Gerenciamento de estoque fácil e automatizado. Mantenha suas operações eficientes e minimize erros com nosso sistema inteligente que acompanha cada produto.
              </p>
            </div>
          </li>
          <li class="column is-4 border-radius is-flex">
            <div class="box">
              <p class="title is-3 is-size-4-mobile">
                Flexibilidade<br>nos Pagamentos
              </p>
              <p class="subtitle is-5 is-size-5-mobile mt-2 has-text-grey">
                Aceite Pix e cartões de crédito sem complicações. Oferecemos um sistema de pagamento seguro, rápido e livre de taxas abusivas, simplificando suas transações comerciais.
              </p>
            </div>
          </li>
          <li class="column is-4 border-radius is-flex">
            <div class="box">
              <p class="title is-3 is-size-4-mobile">
                Gestão Avançada<br>de Administradores
              </p>
              <p class="subtitle is-5 is-size-5-mobile mt-2 has-text-grey">
                Controle total sobre quem gerencia suas lojas e transmissões. Atribua papéis específicos e gerencie permissões para manter sua operação segura e eficiente.
              </p>
            </div>
          </li>
          <li class="column is-4 border-radius is-flex">
            <div class="box">
              <p class="title is-3 is-size-4-mobile">
                Páginas Exclusivas<br>para Cada Transmissão
              </p>
              <p class="subtitle is-5 is-size-5-mobile mt-2 has-text-grey">
                Crie uma vitrine virtual para cada live, com páginas dedicadas que destacam seus produtos e facilitam a compra. Uma maneira perfeita para engajar e converter sua audiência.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>

  <section
    id="channel"
    class="hero is-white is-fullheight p-3"
  >
    <div class="columns is-multiline pb-6 mb-6">
      <div class="column is-full has-text-centered mt-6 mb-6">
        <p class="title is-1 is-size-2-mobile">
          Soluções para todos
        </p>
        <p class="subtitle is-3 is-size-4-mobile has-text-centered has-text-grey">
          Influenciadores, marcas e afiliados de <span class="is-nowrap">todos os tamanhos</span>
        </p>
      </div>

      <div class="column is-4 is-flex">
        <div class="card border-radius">
          <div class="card-image">
            <figure class="image is-4by4">
              <img
                alt="Placeholder image"
                src="@/assets/images/pov-male-influencer-recording-podcast-episode-camera-using-sound-equipment-studio-portrait-content-creator-talking-audience-filming-social-media-channel-vlog.jpg"
              >
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                <p class="title is-4">
                  Para<br>Influenciadores
                </p>
              </div>
            </div>

            <div class="content">
              <p class="subtitle is-5 is-size-5-mobile mt-2 has-text-grey">
                Potencialize sua Influência e Monetize sua Audiência. Com a LiveLink, acompanhe o engajamento dos seus seguidores em tempo real e transforme sua popularidade em vendas. Concentre-se em criar conteúdo cativante enquanto nós cuidamos do resto – aumente sua receita de forma eficaz e interativa.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="column is-4 is-flex">
        <div class="card border-radius">
          <div class="card-image">
            <figure class="image is-4by4">
              <img
                alt="Placeholder image"
                src="@/assets/images/small-business-owner-selling-clothes-online.jpeg"
              >
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                <p class="title is-4">
                  Para Marcas<br>de Todos os Tamanhos
                </p>
              </div>
            </div>

            <div class="content">
              <p class="subtitle is-5 is-size-5-mobile mt-2 has-text-grey">
                Expanda Seu Alcance e Fortaleça Sua Presença Online. A LiveLink é o canal que sua marca precisa para alcançar novos públicos e solidificar a confiança com os clientes existentes. Descubra um universo de possibilidades para aumentar suas vendas e engajamento, seja você uma startup emergente ou uma marca estabelecida.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="column is-4 is-flex">
        <div class="card border-radius">
          <div class="card-image">
            <figure class="image is-4by4">
              <img
                alt="Placeholder image"
                src="@/assets/images/man-for-affiliate-market.jpeg"
              >
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                <p class="title is-4">
                  Para<br>Afiliados
                </p>
              </div>
            </div>
            <div class="content">
              <p class="subtitle is-5 is-size-5-mobile mt-2 has-text-grey">
                Maximize Suas Comissões e Conecte-se com Seguidores. Com a LiveLink, afiliados têm a ferramenta ideal para ampliar a receita através de comissões. Faça mais do que apenas promover produtos; crie uma experiência de compra envolvente e aumente a fidelidade do seu público, vendendo em tempo real e acompanhando o engajamento de perto.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    id="channel"
    class="hero is-grey p-3 mt-6 mb-6"
  >
    <div class="columns is-multiline is-vcentered m-0">
      <div class="column is-6 is-offset-1 mt-6 mb-6">
        <p class="title is-2">
          Transforme suas vendas com o poder das <span class="is-underlined">vendas ao vivo</span>
        </p>
        <p class="subtitle is-3 is-size-4-mobile has-text-grey">
          Inicie sua jornada de sucesso em Live Commerce agora mesmo!
        </p>
        <p>
          <button
            type="button"
            :class="[
              'button',
              'is-primary',
              'is-large',
              'is-white',
              'is-outline',
              {
                'is-loading': isLoading,
              },
            ]"
            :disabled="isLoading"
            @click="signupNow"
          >
            <p>Ative sua loja</p>
          </button>
        </p>
      </div>

      <div class="column is-4 is-multiline">
        <figure class="image is-4by4 box">
          <img
            alt="Placeholder image"
            src="@/assets/images/redhead-with-heart.jpeg"
          >
        </figure>
      </div>
    </div>
  </section>

  <section
    id="about-us"
    class="hero is-white is-fullheight"
  >
    <div class="hero-body">
      <div class="columns is-multiline is-vcentered m-0">
        <div class="column is-full has-text-centered mt-6 mb-6">
          <p class="title is-1 is-size-2-mobile">
            Sobre Nós 🌟
          </p>
          <p class="subtitle is-3 is-size-4-mobile has-text-centered has-text-grey">
            Inovação e Empreendedorismo: Revolucionando o Comércio ao Vivo no Brasil
          </p>
        </div>
        <div class="column is-4 is-flex">
          <div class="card">
            <div class="card-media">
              <figure class="image is-4by4">
                <img
                  alt="Placeholder image"
                  src="@/assets/images/about-us-img1.jpg"
                >
              </figure>
            </div>
            <div class="card-content">
              <p class="title is-3">
                Quem Somos
              </p>
              <p class="subtitle is-5 is-size-5-mobile mt-2 has-text-grey">
                Nascidos no coração vibrante do ecossistema de startups brasileiro, somos uma equipe movida pela paixão em inovar no comércio digital. A LiveLink foi criada com a visão de transformar transmissões ao vivo em uma poderosa ferramenta de vendas, acessível a todas as dimensões de negócios.
              </p>
            </div>
          </div>
        </div>
        <div class="column is-4 is-flex">
          <div class="card">
            <div class="card-media">
              <figure class="image is-4by4">
                <img
                  alt="Placeholder image"
                  src="@/assets/images/our-mission.jpeg"
                >
              </figure>
            </div>
            <div class="card-content">
              <p class="title is-3">
                Nossa Missão
              </p>
              <p class="subtitle is-5 is-size-5-mobile mt-2 has-text-grey">
                Com a missão de democratizar o live streaming, a LiveLink se empenha em tornar as vendas ao vivo uma realidade palpável para todos, desde influenciadores emergentes a marcas estabelecidas. Estamos aqui para que cada negócio, grande ou pequeno, possa prosperar no cenário digital.
              </p>
            </div>
          </div>
        </div>
        <div class="column is-4 is-flex">
          <div class="card">
            <div class="card-media">
              <figure class="image is-4by4">
                <img
                  alt="Placeholder image"
                  src="@/assets/images/pov-female-influencer-selling-makeup.jpg"
                >
              </figure>
            </div>
            <div class="card-content">
              <p class="title is-3">
                Como funciona
              </p>
              <p class="subtitle is-5 is-size-5-mobile mt-2 has-text-grey">
                Simplificamos o complexo, permitindo que você se concentre no essencial: vendas eficientes e engajamento significativo. Com uma interface intuitiva, facilitamos desde o cadastro de lojas até a gestão de produtos e a realização de lives. Transformamos o desafio de vender online em uma jornada simples e gratificante.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    id="contact-us"
    class="hero is-grey-ter is-halfheight"
  >
    <div class="columns is-multiline is-vcentered p-5 m-0 mb-6">
      <div class="column is-6 is-offset-3 has-text-centered pt-6 pb-6">
        <p class="title is-1 is-size-2-mobile">
          Entre em contato
        </p>
        <p class="subtitle is-3 is-size-4-mobile has-text-centered has-text-grey">
          Tem alguma dúvida ou sugestão?
        </p>
      </div>
      <div class="column is-6 is-offset-3 box p-6">
        <form
          @keydown.enter.prevent
          @submit.prevent="submitForm"
        >
          <component
            :is="TextFieldInput"
            v-model="name"
            input-id="name"
            :is-loading="isLoading"
            :input-required="true"
            input-label="Nome Completo"
            input-placeholder="Como consta no seu documento"
            :input-min-length="6"
            :input-min-words="2"
            :input-max-length="256"
            input-min-words-error="Preencha seu nome completo"
            input-auto-complete="name"
            :input-error="nameError"
            @update:input-error="(value) => (nameError = value)"
          />

          <component
            :is="EmailInput"
            v-model="email"
            input-id="emailId"
            :is-loading="isLoading"
            :input-required="true"
            input-label="Email"
            input-placeholder="Campo obrigatório"
            :input-error="emailError"
            @update:input-error="(value) => (emailError = value)"
          />
          <component
            :is="TextArea"
            v-model="newMessage"
            :read-only="false"
            label="Mensagem"
            theme="bubble"
            input-id="description"
            input-placeholder="Envie sua dúvida ou sugestão..."
            @update:model-value="(value) => {
              newMessage = value;
            }"
          />
          <div class="field has-text-right pt-5">
            <div class="control">
              <button
                :class="[
                  'button',
                  'is-primary',
                  {
                    'is-loading': isLoading,
                  },
                ]"
                :disabled="isLoading || !submitEnabled"
                type="submit"
              >
                Enviar mensagem
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>

  <section
    id="community"
    class="hero is-white p-3 pt-6 pb-6"
  >
    <div class="columns is-multiline is-vcentered  has-text-centered-mobile">
      <div class="column is-2 is-offset-2 is-multiline">
        <figure class="image is-4by4">
          <img
            class="box p-0"
            alt="Placeholder image"
            src="@/assets/images/whats-app-comunity.jpeg"
          >
        </figure>
      </div>
      <div class="column is-6 mt-6 mb-6">
        <JoinCommunityComponent>
          <p class="title is-3">
            Seja notificado quando a próxima live começar!
          </p>
          <p class="subtitle is-4 is-size-5-mobile has-text-grey">
            Faça parte da nossa comunidade e tenha acesso à eventos exclusivos.
          </p>
        </JoinCommunityComponent>
      </div>
    </div>
  </section>

  <footer class="footer p-6">
    <ul class="mb-6 has-text-centered mobile">
      <li>
        <RouterLink
          :to="{ name: 'TermsAndConditionsPageShoppers' }"
          class="navbar-item"
          aria-label="navbar main logo"
        >
          Termos de Uso para Compradores
        </RouterLink>
      </li>
      <li>
        <RouterLink
          :to="{ name: 'TermsAndConditionsPage' }"
          class="navbar-item"
          aria-label="navbar main logo"
        >
          Termos de Uso para Parceiros Comerciais
        </RouterLink>
      </li>
      <li>
        <RouterLink
          :to="{ name: 'PrivacyPage' }"
          class="navbar-item"
          aria-label="navbar main logo"
        >
          Política de Privacidade e Proteção dos Dados Pessoais
        </RouterLink>
      </li>
    </ul>
    <div class="has-text-centered">
      <p>
        <strong>LiveLink</strong>
        &copy;
        {{ new Date().getFullYear() }}
      </p>
      <p class="subtitle is-6">
        made with love in SP 💚
      </p>
    </div>
  </footer>
  <LoadingComponent
    v-if="isLoading"
    :full-screen="fullScreenLoading"
  />
  <notifications classes="toast-notification" />
</template>

<script setup lang="ts">
import {
  ref,
  onBeforeMount,
  onMounted,
  computed,
} from 'vue';
import { useRouter } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';
import useAuthStore from '@/store/auth';
import useContactUsStore from '@/store/contactUs';
import ContactUs from '@/models/contactUs';
import AppHeader from '@/components/header/AppHeader.vue';
import TextFieldInput from '@/components/form/TextFieldInput.vue';
import TextArea from '@/components/form/TextArea.vue';
import EmailInput from '@/components/form/EmailInput.vue';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';
import JoinCommunityComponent from '@/components/shared/whatsapp/JoinCommunityComponent.vue';

const router = useRouter();
const { isAuthenticated } = useAuthStore();
const { contactUsDoCreate } = useContactUsStore();

/*
  Data
*/
const isLoading = ref(true);
const fullScreenLoading = ref(true);
const name = ref('');
const nameError = ref('');
const email = ref('');
const emailError = ref('');
const newMessage = ref('');

/*
  Methods
*/
const submitEnabled = computed(() => !isLoading.value
  && (!!name.value && !nameError.value)
  && (!!email.value && !emailError.value)
  && (!!newMessage.value && newMessage.value.length > 10));
const signupNow = () => {
  isLoading.value = true;
  router.push({
    name: 'SessionSignupPage',
  });
};

const submitForm = () => {
  fullScreenLoading.value = false;
  isLoading.value = true;
  const contactUsData = new ContactUs({
    supportType: 'contact-us',
    senderName: name.value,
    senderContact: email.value,
    senderContactType: 'email',
    senderMessage: newMessage.value,
  });
  contactUsDoCreate(contactUsData)
    .then(() => {
      isLoading.value = false;
      name.value = '';
      email.value = '';
      newMessage.value = '';

      notify({
        duration: '50000',
        type: 'success',
        title: 'Uhuuul',
        text: 'Mensagem enviada com sucesso, entraremos em contato o mais breve possível',
      });
    })
    .catch(() => {
      isLoading.value = false;
      notify({
        duration: 5000,
        type: 'error',
        title: 'Ooops',
        text: 'Não foi possível completar sua solicitação. Tente novamente mais tarde. (x001)',
      });
    });
};
/*
  Hooks
*/
onBeforeMount(() => {
  if (isAuthenticated()) {
    router.push({
      name: 'DashboardPage',
    });
  }
});
onMounted(() => {
  isLoading.value = false;
});
</script>

<style scoped lang="scss">
input {
  border-color: none;
  border-shadow: none;
}
.container {
  text-align: center;
}

.border-radius {
  border-radius: 24px;
  overflow: hidden;
}
.benefits {
  list-style: none;
  padding: 0;
  margin: 0;
  li div {
    padding: 1rem;
    border: 1px solid #dbdbdb;
    padding: 32px;
  }
}
</style>

<template>
  <div
    v-if="isStoreModeCustomer()"
  >
    <UserHeader />
  </div>
  <div
    v-else-if="isStoreModeMerchant()"
  >
    <MerchantHeader />
  </div>
  <div
    v-else
  >
    <HomeHeader />
  </div>
</template>

<script setup lang='ts'>
import useAuthStore from '@/store/auth';
import HomeHeader from '@/components/header/HomeHeader.vue';
import UserHeader from '@/components/header/UserHeader.vue';
import MerchantHeader from '@/components/header/MerchantHeader.vue';

const { isStoreModeCustomer, isStoreModeMerchant } = useAuthStore();
</script>

<style scoped lang='scss'>
</style>

import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "#loading-wrapper" }, [
    _createElementVNode("div", {
      class: _normalizeClass([
        $props.fullScreen ? 'background-loader-full' : 'background-loader'
      ]),
      style: _normalizeStyle([$setup.loaderStyle])
    }, [
      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "mr-4 is-size-4" }, " Loading ", -1)),
      _createElementVNode("div", {
        class: "rounded-ball",
        style: _normalizeStyle([$setup.ballStyle])
      }, null, 4)
    ], 6)
  ]))
}
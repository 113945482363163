import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, unref as _unref, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "navbar-brand" }
const _hoisted_2 = { class: "buttons" }
const _hoisted_3 = ["disabled"]

import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import AuthStore from '@/store/auth';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';
import SmallLogoComponent from '@/components/shared/SmallLogoComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserHeader',
  setup(__props) {

const router = useRouter();
const {
  isAuthenticated,
  authDoLogout,
} = AuthStore();

/*
  Data
*/
const isActive = ref(false);
const isLoading = ref(false);
const submitDisabled = ref(false);
const topMenu = ref('');

/*
  Methods
*/
const logout = async () => {
  isLoading.value = true;
  submitDisabled.value = true;
  authDoLogout().then(() => {
    router.push({ name: 'SessionLoginPage' });
  }).catch((error) => {
    let { message } = error;
    if (message === undefined) {
      message = error;
    }
    if (error === undefined) {
      message = 'Não foi possível completar sua solicitação. Tente novamente mais tarde. (x002)';
    }

    isLoading.value = false;
    submitDisabled.value = false;
  }).finally(() => {
    window.location.reload();
  });
};
const goHome = () => {
  router.push({
    name: 'DashboardPage',
  });
  // window.open('/', '_self');
};
// const closeMenu = () => {
//   isActive.value = false;
// };
const myProfile = () => {
  // isLoading.value = true;
  router.push({
    name: 'UserProfileManagerPage',
  });
  // setTimeout(() => {
  //   isLoading.value = false;
  // }, 5000);
};
// const switchAccount = async () => {
//   router.push({ name: 'SessionSwitchAccountPage' });
// };
const toggleMenu = () => {
  isActive.value = !isActive.value;
};

/*
  Hooks
*/
onMounted(() => {
  // topMenu.value.addEventListener('click', closeMenu);
});


return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", {
      ref_key: "topMenu",
      ref: topMenu,
      class: "navbar is-fixed-top is-white has-background-white",
      role: "navigation",
      "aria-label": "main navigation"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_RouterLink, {
          to: { name: 'HomePage' },
          class: "navbar-item",
          "aria-label": "navbar main logo",
          onClick: goHome
        }, {
          default: _withCtx(() => [
            _createVNode(SmallLogoComponent)
          ]),
          _: 1
        }),
        _createElementVNode("a", {
          href: "#top",
          class: _normalizeClass(["navbar-burger", { 'is-active': isActive.value }]),
          "aria-label": "Toggle Menu",
          onClick: toggleMenu,
          onKeyup: _withKeys(toggleMenu, ["enter"])
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("span", { "aria-hidden": "true" }, null, -1),
          _createElementVNode("span", { "aria-hidden": "true" }, null, -1),
          _createElementVNode("span", { "aria-hidden": "true" }, null, -1)
        ]), 34)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(['navbar-menu', { 'is-active': isActive.value }])
      }, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "navbar-start" }, null, -1)),
        _cache[4] || (_cache[4] = _createElementVNode("hr", null, null, -1)),
        _createElementVNode("div", {
          class: _normalizeClass(['navbar-end', { 'is-hidden': !_unref(isAuthenticated)() }])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(['navbar-item', {
            'is-active': _unref(router).currentRoute.value.fullPath.includes('/profile/')
          }])
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("button", {
                class: "button is-white",
                type: "button",
                disabled: submitDisabled.value,
                onClick: myProfile
              }, [
                _createVNode(_component_font_awesome_icon, {
                  class: "fa-lg mr-2",
                  icon: { prefix: 'fa', iconName: 'user-circle' }
                }),
                _cache[1] || (_cache[1] = _createTextVNode(" Minha conta "))
              ], 8, _hoisted_3),
              _createElementVNode("button", {
                class: "button is-hidden-tablet is-outlined",
                type: "button",
                onClick: logout
              }, [
                _cache[2] || (_cache[2] = _createTextVNode(" Logout ")),
                _createVNode(_component_font_awesome_icon, {
                  class: "fa-lg ml-2",
                  icon: { prefix: 'fa', iconName: 'sign-out' }
                })
              ])
            ])
          ], 2)
        ], 2)
      ], 2)
    ], 512),
    (isLoading.value)
      ? (_openBlock(), _createBlock(LoadingComponent, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoadingComponent',
  props: {
  background: {
    type: String,
    default: 'rgba(255,255,255,1)',
  },
  color: {
    type: String,
    default: '#35d0ba',
  },
  borderWidth: {
    type: Number,
    default: 5,
  },
  duration: {
    type: Number,
    default: 0.5,
  },
  size: {
    type: Number,
    default: 30,
  },
  fullScreen: {
    type: Boolean,
    default: false,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const loaderStyle = ref(props.background);
const ballStyle = ref({
  borderColor: `${props.color} ${props.color} ${props.color} transparent`,
  borderWidth: `${props.borderWidth}px`,
  borderStyle: 'solid',
  animationDuration: `${props.duration}s`,
  width: `${props.size}px`,
  height: `${props.size}px`,
});

const __returned__ = { props, loaderStyle, ballStyle, ref }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
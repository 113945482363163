<template>
  <button
    type="button"
    class="button is-outlined is-white ml-2"
    :style="['background-color: transparent; color: #FFF', 'color: ' + props.buttonColor]"
    @click.prevent="toggleShoppingCart"
  >
    <font-awesome-icon
      style="width: 24px; height: 24px"
      :icon="{ prefix: 'fa', iconName: 'shopping-cart' }"
    />
    <span
      v-if="getShoppingCartTotal(props.streamUuid) > 0"
      class="tag is-danger is-rounded"
      style="margin-left: -0.3rem;"
    >
      {{ getShoppingCartTotal(props.streamUuid) }}
    </span>
    <span class="ml-2 is-hidden-mobile">Carrinho</span>
  </button>
  <teleport
    v-if="showShoppingCart"
    to="#shopping-cart"
    class="wrapper is-unselectable"
  >
    <div
      class="wrapper-background"
      @click.prevent="toggleShoppingCart"
      @keyup.prevent="toggleShoppingCart"
    />
    <div class="wrapper-content">
      <section
        style=""
        class="section shopping-cart p-4"
      >
        <div class="columns is-multiline">
          <div class="column is-full is-flex is-justify-content-flex-end">
            <button
              class="button p-4 is-white is-transparent"
              @click="showShoppingCart = false"
            >
              <span class="fa-2x">
                <font-awesome-icon
                  :icon="{ prefix: 'fa', iconName: 'close' }"
                />
              </span>
            </button>
          </div>
          <div class="column is-full">
            <ul
              v-if="getShoppingCartTotal(props.streamUuid) == 0"
              class=""
            >
              <li>
                <p class="subtitle is-size-6 has-text-centered">
                  sua carrinho está vazio
                </p>
              </li>
            </ul>
            <ul
              v-else
              class="p-0"
            >
              <li
                v-for="(item, index) in productQuantity"
                :key="index"
                class="columns is-multiline is-mobile is-vcentered mb-4"
              >
                <div class="column is-4-mobile is-3 p-1 m-0 is-vcentered">
                  <figure
                    v-if="item.product.getImageUrl()"
                    class="m-auto p-0 has-text-centered"
                  >
                    <img
                      :src="item.product.getImageUrl('/-/scale_crop/300x350/center/')"
                      :alt="item.product.name"
                    >
                  </figure>
                  <figure
                    v-else
                    class="m-auto p-0 has-text-centered"
                  >
                    <img
                      alt="sem imagem"
                      src="@/assets/logo.png"
                      style="max-height: 88px; max-width: 100%; padding: 8px;"
                    >
                  </figure>
                </div>
                <div class="column p-1 m-0">
                  <div class="columns is-multiline is-vcentered">
                    <div class="column is-full pb-0">
                      <p class="title is-size-6 is-size-7-mobile m-0">
                        {{ item.productSku.getTitle() }}
                      </p>
                      <p class="is-lowercase is-size-7">
                        {{ item.productSku.getFormattedVariantions() }}
                      </p>
                      <span class="subtitle is-size-7">
                        {{ formatPrice(item.getTotalPrice()) }}
                      </span>
                    </div>
                    <div class="column is-narrow">
                      <div class="subtitle is-size-6 is-flex is-align-items-center">
                        <button
                          class="button is-small"
                          type="button"
                          @click.prevent="decreaseShoppingCartItemQuantity(
                            item, undefined)"
                        >
                          <font-awesome-icon
                            class="fa-sm"
                            :icon="{ prefix: 'fa', iconName: 'minus' }"
                          />
                        </button>
                        <p class="m-2">
                          {{ item.quantity }}
                        </p>
                        <button
                          type="button"
                          class="button is-small"
                          @click.prevent="increaseShoppingCartItemQuantity(
                            item, undefined)"
                        >
                          <font-awesome-icon
                            class="fa-sm"
                            :icon="{ prefix: 'fa', iconName: 'plus' }"
                          />
                        </button>
                        <p
                          v-if="item.quantity >= 10"
                          class="subtitle is-6 ml-4 has-text-grey"
                        >
                          máximo de 10 unidades
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <hr class="mb-4">
              <li class="column is-full">
                <div class="columns is-mobile is-vcentered is-multiline">
                  <div class="column is-narrow">
                    <p class="title is-5">
                      TOTAL
                    </p>
                  </div>
                  <div class="column has-text-right">
                    {{ shoppingCart.getFormattedTotalPrice() }}
                  </div>
                  <div class="column is-full">
                    <p class="subtitle is-7 has-text-centered">
                      Caso tenha um cupom de desconto, você deverá inserir o código no checkout.
                    </p>
                  </div>
                  <div class="column is-full">
                    <button
                      type="button"
                      class="button is-danger is-fullwidth"
                      @click="startCheckout"
                    >
                      Seguir para checkout
                      <font-awesome-icon
                        class="fa-1x ml-2"
                        :icon="{ prefix: 'fa', iconName: 'external-link' }"
                      />
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  </teleport>
</template>

<script setup lang='ts'>
import {
  ref,
  defineProps,
  onBeforeMount,
  // watch,
} from 'vue';
import useShoppingCartStore from '@/store/yampi/shoppingCart';
import MixpanelStore from '@/store/mixpanel/broadcastLive';
import Product from '@/models/yampi/product/product';
import ShoppingCart from '@/models/yampi/shoppingCart/shoppingCart';

const mixpanel = new MixpanelStore();
const {
  // shoppingCartStore,
  getShoppingCartTotal,
  getShoppingCart,
  formatPrice,
} = useShoppingCartStore();

const props = defineProps({
  buttonColor: {
    type: String,
    reqiore: false,
    default: '#FFF',
  },
  streamUuid: {
    type: String,
    required: true,
  },
  merchantUuid: {
    type: String,
    required: true,
  },
});

/*
  Data
*/
const streamUuid = ref('');
const merchantUuid = ref('');
const shoppingCart = ref({} as ShoppingCart);
const showShoppingCart = ref(false);
const productQuantity = ref([] as Product[]);

/*
  Methods
*/
const increaseShoppingCartItemQuantity = async (
  item: Product,
  quantity: number,
) => {
  shoppingCart.value.increaseQuantity(item.productSku.token, quantity);
  window.xxx = item;
  console.log(item);
  mixpanel.shoppingCartIncreaseQtyClick(
    props.merchantUuid,
    props.streamUuid,

    false, // isAdmin
    item.productSku.getTitle(),
    item.productSku.getFormattedPrice(),
    null, // image
    'livelink-yampi',
  );
};
const decreaseShoppingCartItemQuantity = async (
  item: Product,
  quantity: number,
) => {
  shoppingCart.value.decreaseQuantity(item.productSku.token, quantity);
  mixpanel.shoppingCartReduceQtyClick(
    props.merchantUuid,
    props.streamUuid,

    false, // isAdmin
    item.productSku.getTitle(),
    item.productSku.getFormattedPrice(),
    null, // image
    'livelink-yampi',
  );
};
const startCheckout = async () => {
  const url = shoppingCart.value.getCheckoutUrl(streamUuid.value, merchantUuid.value, 'shoppingCart');

  await mixpanel.shoppingCartCheckoutClick(
    props.merchantUuid,
    props.streamUuid,

    false, // isAdmin
    shoppingCart.value.getProductNames(),
    shoppingCart.value.getTotalPrice(),
  );

  window.open(url, '_blank');
};
const toggleShoppingCart = async () => {
  shoppingCart.value = await getShoppingCart(props.streamUuid);
  if (shoppingCart.value !== undefined) {
    productQuantity.value = shoppingCart.value.productQuantity;
  }
  showShoppingCart.value = !showShoppingCart.value;
};
onBeforeMount(() => {
  streamUuid.value = props.streamUuid;
  merchantUuid.value = props.merchantUuid;
});
// watch(shoppingCartStore, async () => {
//   if (!showShoppingCart.value) {
//     toggleShoppingCart();
//   }
// });
</script>

<style scoped lang='scss'>
.wrapper {
  position: fixed;
  z-index: 999;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
}
.wrapper-content {
  overflow: scroll;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 90vw;
  max-width: 800px;
  background-color: #FFF;
  padding: 1rem;
  z-index: 5 !important;
}
.wrapper-background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(10,10,10,.86);
  z-index: 4 !important;
}
</style>

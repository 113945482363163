import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import {
  ref,
  onBeforeMount,
} from 'vue';
import { useRoute } from 'vue-router';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'MerchantExternalLiveReportLayout',
  setup(__props) {

const router = useRoute();

/*
  Data
*/
const blur = ref(false);
const isLoading = ref(true);
const initialPage = ref(1);
const initialLimit = ref(10);
const scheduledId = ref('');

const merchantUuid = ref('');
const date = ref('');
const platform = ref('');
const account = ref('');
const content = ref('');

/*
  Hooks
*/
onBeforeMount(() => {
  merchantUuid.value = router.params.uuid;
  date.value = router.params.date;
  platform.value = router.params.platform;
  account.value = router.params.account;
  content.value = router.query.content;
  if (router.meta.page) {
    initialPage.value = router.meta.page;
  } else {
    initialPage.value = router.query.page;
  }
  if (router.meta.limit) {
    initialLimit.value = router.meta.limit;
  } else {
    initialLimit.value = router.query.limit;
  }
  blur.value = router.query.b;
  scheduledId.value = router.query.schedId;
  isLoading.value = false;
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_RouterView, {
      "merchant-uuid": merchantUuid.value,
      date: date.value,
      platform: platform.value,
      account: account.value,
      content: content.value,
      "initial-page": initialPage.value,
      "initial-limit": initialLimit.value,
      "scheduled-id": scheduledId.value,
      blur: blur.value
    }, null, 8, ["merchant-uuid", "date", "platform", "account", "content", "initial-page", "initial-limit", "scheduled-id", "blur"]),
    (isLoading.value)
      ? (_openBlock(), _createBlock(LoadingComponent, {
          key: 0,
          "full-screen": true
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})
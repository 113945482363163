<template>
  <RouterView
    :merchant-uuid="merchantUuid"
    :date="date"
    :platform="platform"
    :account="account"
    :content="content"
    :initial-page="initialPage"
    :initial-limit="initialLimit"
    :scheduled-id="scheduledId"
    :blur="blur"
  />
  <LoadingComponent
    v-if="isLoading"
    :full-screen="true"
  />
</template>

<script setup lang="ts">
import {
  ref,
  onBeforeMount,
} from 'vue';
import { useRoute } from 'vue-router';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';

const router = useRoute();

/*
  Data
*/
const blur = ref(false);
const isLoading = ref(true);
const initialPage = ref(1);
const initialLimit = ref(10);
const scheduledId = ref('');

const merchantUuid = ref('');
const date = ref('');
const platform = ref('');
const account = ref('');
const content = ref('');

/*
  Hooks
*/
onBeforeMount(() => {
  merchantUuid.value = router.params.uuid;
  date.value = router.params.date;
  platform.value = router.params.platform;
  account.value = router.params.account;
  content.value = router.query.content;
  if (router.meta.page) {
    initialPage.value = router.meta.page;
  } else {
    initialPage.value = router.query.page;
  }
  if (router.meta.limit) {
    initialLimit.value = router.meta.limit;
  } else {
    initialLimit.value = router.query.limit;
  }
  blur.value = router.query.b;
  scheduledId.value = router.query.schedId;
  isLoading.value = false;
});
</script>

<style scoped lang="scss">
</style>

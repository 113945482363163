import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import {
  ref,
  computed,
  onBeforeMount,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';
import useMerchantStore from '@/store/merchant';
import Merchant from '@/models/merchant';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'MerchantManagerLayout',
  setup(__props) {

const router = useRouter();
const {
  getMyMerchant,
  merchantsDoGetMy,
  merchantStore,
} = useMerchantStore();

/*
  Data
*/
const myMerchant = ref({});
const isLoading = ref(true);
const uuid = ref('');

/*
  Computed
*/
const merchantUuid = computed(() => router.currentRoute.value.params.uuid);

/*
  Methods
*/
const redirectDashboard = ((error: string) => router.push({
  name: 'DashboardPage',
  query: {
    message: 'Não foi possível carregar sua loja. Tente novamente em instantes.',
    detail: error,
    type: 'error',
  },
}));

const resetFields = async (merchant?: Merchant) => {
  isLoading.value = true;
  if (merchant == null || merchant === undefined) {
    await merchantsDoGetMy()
      .then(async (response) => {
        if (response === undefined) {
          throw new Error('Não foi possível completar sua solicitação. Tente novamente mais tarde.');
        }
        const m = response.data.find((v: Merchant) => v.uuid === uuid.value);
        if (m === undefined || m.uuid === undefined) {
          redirectDashboard('Não foi possível completar sua solicitação. Verifique o link e tente novamente');
          return;
        }
        myMerchant.value = m;
      })
      .catch((error) => {
        redirectDashboard(error);
      });
  } else {
    myMerchant.value = merchant;
  }
  isLoading.value = false;
};

/*
  Hooks
*/
onBeforeMount(async () => {
  uuid.value = merchantUuid.value;
  resetFields();
});
watch(merchantStore, async () => {
  myMerchant.value = getMyMerchant(uuid.value);
}, { deep: true });

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_RouterView, {
      "my-merchant": myMerchant.value,
      "onUpdate:myMerchant": resetFields
    }, null, 8, ["my-merchant"]),
    (isLoading.value)
      ? (_openBlock(), _createBlock(LoadingComponent, {
          key: 0,
          "full-screen": true
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})
class InstagramLiveMetadata {
  id: string;

  mediaId: string;

  accountId: string;

  accountUsername: string;

  commentsCount: number;

  likeCount: number;

  mediaType: string;

  permalink: string;

  shortcode: string;

  createdAt: Date;

  updatedAt: Date;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor({
    id,
    mediaId,
    accountId,
    accountUsername,
    commentsCount,
    likeCount,
    mediaType,
    permalink,
    shortcode,
    createdAt,
    updatedAt,
  }: {
    id: string;
    mediaId: string;
    accountId: string;
    accountUsername: string;
    commentsCount: number;
    likeCount: number;
    mediaType: string;
    permalink: string;
    shortcode: string;
    createdAt: Date;
    updatedAt: Date;
  }) {
    this.id = id;
    this.mediaId = mediaId;
    this.accountId = accountId;
    this.accountUsername = accountUsername;
    this.commentsCount = commentsCount;
    this.likeCount = likeCount;
    this.mediaType = mediaType;
    this.permalink = permalink;
    this.shortcode = shortcode;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  getLiveFirstMessageDate(): string {
    return this.createdAt.toLocaleDateString('pt-BR', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  }

  getLiveFirstMessageTime(): string {
    return this.createdAt.toLocaleTimeString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromApiResponse(columns: string[], rows: any[][]): InstagramLiveMetadata[] {
    if (rows.length === 0) {
      return [];
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return rows.map((row: any[]) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const v: any = {};
      columns.forEach((column, index) => {
        const camelCaseKey = column.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
        if (row[index] && (camelCaseKey === 'updatedAt' || camelCaseKey === 'createdAt')) {
          v[camelCaseKey] = new Date(row[index]);
        } else {
          v[camelCaseKey] = row[index];
        }
      });
      return new InstagramLiveMetadata(v);
    });
  }
}

export default InstagramLiveMetadata;

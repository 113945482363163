<template>
  <RouterView
    v-if="!isLoading"
    :plugin-id="pluginId"
    :plugin-type="pluginType"
    :metadatas="metadatas"
    :metadatas-pagination="metadatasPagination"
  />
  <LoadingComponent
    v-if="isLoading"
    :full-screen="true"
  />
</template>

<script setup lang="ts">
import { ref, computed, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import usePluginStore from '@/store/plugin/plugin';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';
import InstagramLiveMetadata from '@/models/plugin/instagramLiveMetadata';
import Pagination from '@/models/pagination';

const {
  pluginGetLiveMetadatas,
} = usePluginStore();
const router = useRouter();

/*
  Computed
*/
const pluginId = computed(() => router.currentRoute.value.params.pluginId);
const pluginType = computed(() => router.currentRoute.value.params.pluginType);

/*
  Data
*/
const isLoading = ref(true);
const metadatas = ref([] as InstagramLiveMetadata[]);
const metadatasPagination = ref({} as Pagination);
const orderBy = ref('createdAt');
const limit = ref(10);
const order = ref(-1);
const page = ref(1);

/*
  Methods
*/
const loadPluginLiveMetadatas = async () => {
  isLoading.value = true;

  interface InstagramLiveMetadataResponse {
    data: InstagramLiveMetadata[];
    pagination: Pagination;
  }

  try {
    const response = await pluginGetLiveMetadatas({
      pluginId: `${pluginId.value}`,
      pluginType: `${pluginType.value}`,
      page: page.value,
      limit: limit.value,
      orderBy: orderBy.value,
      order: order.value,
    }) as InstagramLiveMetadataResponse;

    isLoading.value = false;

    if (!response) {
      return;
    }

    metadatas.value = response.data;
    metadatasPagination.value = response.pagination;
  } catch (error) {
    console.error('Error', error);
    isLoading.value = false;
  }
};

/*
  Hooks
*/
onBeforeMount(async () => {
  await loadPluginLiveMetadatas();
});
</script>

<style scoped lang="scss"></style>

<template>
  <div class="field">
    <div class="control">
      <label
        :for="props.inputId"
        class="label"
      >
        {{ props.inputLabel }}
        <span
          v-if="props.inputRequired"
          class="tag"
        >
          obrigatório
        </span>
        <p
          v-if="props.inputSubLabel.length > 0"
          class="help"
        >
          {{ props.inputSubLabel }}
        </p>
        <slot />
        <input
          :id="props.inputId"
          :disabled="props.isLoading"
          :value="props.modelValue"
          :class="[
            'input',
            'is-fullwidth',
            {
              'is-danger': props.inputError,
            },
            {
              'is-success': props.modelValue && !props.inputError,
            },
          ]"
          type="text"
          :autocomplete="props.inputAutoComplete"
          :placeholder="props.inputPlaceholder"
          @input="handleInput"
          @keyup="validateInput"
        >
      </label>
      <p
        v-if="props.inputError"
        class="help is-danger"
      >
        {{ props.inputError }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps, defineEmits } from 'vue';

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
    default: '',
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
  inputId: {
    type: String,
    required: true,
  },
  inputRequired: {
    type: Boolean,
    required: true,
  },
  inputLabel: {
    type: String,
    required: true,
  },
  inputSubLabel: {
    type: String,
    required: false,
    default: '',
  },
  inputPlaceholder: {
    type: String,
    required: true,
  },
  inputError: {
    type: String,
    required: true,
  },
  inputMinLength: {
    type: Number,
    required: true,
  },
  inputMaxLength: {
    type: Number,
    required: true,
  },
  inputMinWords: {
    default: 0,
    type: Number,
    required: false,
  },
  inputMinWordsError: {
    default: 'Campo obrigatório',
    type: String,
    required: false,
  },
  inputAutoComplete: {
    type: String,
    required: true,
  },
});

const emit = defineEmits([
  'update:modelValue',
  'update:inputError',
]);

const inputTrimmed = computed(() : string => props.modelValue.trim());

const handleInput = ((event: InputEvent) => {
  const localModelValue = (event.target as HTMLInputElement).value;
  emit('update:modelValue', localModelValue);
});

const validateInput = (() => {
  if (inputTrimmed.value.length === 0) {
    emit('update:inputError', '');
  } else if (!inputTrimmed.value) {
    emit('update:inputError', 'Campo obrigatório');
  } else if (props.inputMinLength > 0 && inputTrimmed.value.length < props.inputMinLength) {
    emit('update:inputError', `Mínimo de ${props.inputMinLength} caracteres`);
  } else if (props.inputMaxLength > 0 && inputTrimmed.value.length > props.inputMaxLength) {
    emit('update:inputError', `Máximo de ${props.inputMaxLength} caracteres`);
  } else if (props.inputMinWords > 0 && inputTrimmed.value.split(' ').length < props.inputMinWords) {
    emit('update:inputError', props.inputMinWordsError);
  } else {
    emit('update:inputError', '');
  }
});
</script>

<style scoped lang="scss">
/* component styles */
</style>

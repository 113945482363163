import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "control" }
const _hoisted_3 = ["for"]
const _hoisted_4 = {
  key: 0,
  class: "tag"
}
const _hoisted_5 = {
  key: 1,
  class: "help"
}
const _hoisted_6 = ["id", "disabled", "value", "autocomplete", "placeholder"]
const _hoisted_7 = {
  key: 0,
  class: "help is-danger"
}

import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TextFieldInput',
  props: {
  modelValue: {
    type: String,
    required: true,
    default: '',
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
  inputId: {
    type: String,
    required: true,
  },
  inputRequired: {
    type: Boolean,
    required: true,
  },
  inputLabel: {
    type: String,
    required: true,
  },
  inputSubLabel: {
    type: String,
    required: false,
    default: '',
  },
  inputPlaceholder: {
    type: String,
    required: true,
  },
  inputError: {
    type: String,
    required: true,
  },
  inputMinLength: {
    type: Number,
    required: true,
  },
  inputMaxLength: {
    type: Number,
    required: true,
  },
  inputMinWords: {
    default: 0,
    type: Number,
    required: false,
  },
  inputMinWordsError: {
    default: 'Campo obrigatório',
    type: String,
    required: false,
  },
  inputAutoComplete: {
    type: String,
    required: true,
  },
},
  emits: [
  'update:modelValue',
  'update:inputError',
],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const inputTrimmed = computed(() : string => props.modelValue.trim());

const handleInput = ((event: InputEvent) => {
  const localModelValue = (event.target as HTMLInputElement).value;
  emit('update:modelValue', localModelValue);
});

const validateInput = (() => {
  if (inputTrimmed.value.length === 0) {
    emit('update:inputError', '');
  } else if (!inputTrimmed.value) {
    emit('update:inputError', 'Campo obrigatório');
  } else if (props.inputMinLength > 0 && inputTrimmed.value.length < props.inputMinLength) {
    emit('update:inputError', `Mínimo de ${props.inputMinLength} caracteres`);
  } else if (props.inputMaxLength > 0 && inputTrimmed.value.length > props.inputMaxLength) {
    emit('update:inputError', `Máximo de ${props.inputMaxLength} caracteres`);
  } else if (props.inputMinWords > 0 && inputTrimmed.value.split(' ').length < props.inputMinWords) {
    emit('update:inputError', props.inputMinWordsError);
  } else {
    emit('update:inputError', '');
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", {
        for: props.inputId,
        class: "label"
      }, [
        _createTextVNode(_toDisplayString(props.inputLabel) + " ", 1),
        (props.inputRequired)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, " obrigatório "))
          : _createCommentVNode("", true),
        (props.inputSubLabel.length > 0)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(props.inputSubLabel), 1))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default"),
        _createElementVNode("input", {
          id: props.inputId,
          disabled: props.isLoading,
          value: props.modelValue,
          class: _normalizeClass([
            'input',
            'is-fullwidth',
            {
              'is-danger': props.inputError,
            },
            {
              'is-success': props.modelValue && !props.inputError,
            },
          ]),
          type: "text",
          autocomplete: props.inputAutoComplete,
          placeholder: props.inputPlaceholder,
          onInput: handleInput,
          onKeyup: validateInput
        }, null, 42, _hoisted_6)
      ], 8, _hoisted_3),
      (props.inputError)
        ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(props.inputError), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})
<template>
  <RouterView
    v-if="dataLoaded"
    :stream="myStream"
    :is-loading="isLoading"
    @update:stream="(value) => updateStream(value)"
  />
  <LoadingComponent
    v-if="isLoading"
    :full-screen="!dataLoaded"
  />
  <notifications classes="toast-notification" />
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  onBeforeMount,
} from 'vue';
import { useRouter } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';
import Stream from '@/models/stream';
import useStreamStore from '@/store/stream';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';

const router = useRouter();

const {
  streamsDoGetMy,
  streamsDoUpdateMyStream,
} = useStreamStore();

/*
  Data
*/
const myStream = ref({} as Stream);
const isLoading = ref(false);
const dataLoaded = ref(false);

/*
  Computed
*/
const streamUuid = computed(() => router.currentRoute.value.params.uuid);

/*
  Methods
*/
const load = async () => {
  isLoading.value = true;
  await streamsDoGetMy({
    uuid: streamUuid.value,
  }).then(async (response) => {
    if (response.data.length > 0) {
      [myStream.value] = response.data.filter((stream) => stream.uuid === streamUuid.value);
    }
  }).catch((error) => {
    console.error(error);
  });
  isLoading.value = false;
};
const updateStream = async (value) => {
  const { productsUuid, activeProductsId, showProductsOnLanding } = value;
  isLoading.value = true;
  const response = await streamsDoUpdateMyStream({
    uuid: streamUuid.value,
    productsUuid,
    activeProductsId,
    showProductsOnLanding,
  });
  if (response === undefined) {
    notify({
      duration: 5000,
      type: 'error',
      title: 'Ooops',
      text: 'Não foi possível completar sua solicitação. Tente novamente mais tarde.',
    });
  } else {
    load();
  }
  isLoading.value = false;
};

/*
  Hooks
*/
onBeforeMount(async () => {
  dataLoaded.value = false;
  await load();
  dataLoaded.value = true;
});
</script>

<style lang="scss" scoped>
</style>

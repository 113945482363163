import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withKeys as _withKeys, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/mobile_login.svg'


const _hoisted_1 = { class: "hero is-fullheight" }
const _hoisted_2 = { class: "columns m-0 gradient-container" }
const _hoisted_3 = { class: "column is-flex is-align-items-center pl-0 pr-0" }
const _hoisted_4 = { class: "column is-8 is-offset-2 pt-0" }
const _hoisted_5 = { class: "box" }
const _hoisted_6 = { class: "block is-flex" }
const _hoisted_7 = {
  key: 0,
  class: "error-message has-text-centered p-3"
}
const _hoisted_8 = { class: "field has-text-left" }
const _hoisted_9 = { class: "control" }
const _hoisted_10 = { class: "field mt-6" }
const _hoisted_11 = { class: "control is-flex is-justify-content-flex-end" }
const _hoisted_12 = ["disabled"]
const _hoisted_13 = { class: "has-text-centered" }

import {
  ref,
  computed,
  defineAsyncComponent,
} from 'vue';
import { useRouter } from 'vue-router';
import useAuthStore from '@/store/auth';
import { notify } from '@kyvg/vue3-notification';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginView',
  setup(__props) {

const CPFInput = defineAsyncComponent(() => import('@/components/form/CPFInput.vue'));
const PasswordInput = defineAsyncComponent(() => import('@/components/form/PasswordInput.vue'));
const SmallLogoComponent = defineAsyncComponent(() => import('@/components/shared/SmallLogoComponent.vue'));

const router = useRouter();
const { authDoLogin } = useAuthStore();

/*
  Data
*/
const documentType = ref('CPF');
const document = ref('');
const documentError = ref('');
const password = ref('');
const passwordError = ref('');
const showPassword = ref(false);
const isLoading = ref(false);
const nextUrl = router.currentRoute.value?.query?.redirect;

/*
  Computed
*/
const errorMessage = computed(() => router.currentRoute.value?.query?.error);
const submitEnabled = computed(() => (
  !!document.value && !documentError.value
  && !!password.value && !passwordError.value
));

/*
  Methods
*/
const submitForm = async () => {
  isLoading.value = true;
  await authDoLogin(
    document.value,
    documentType.value,
    password.value,
  ).then(() => {
    if (
      nextUrl !== ''
      && nextUrl !== undefined
      && nextUrl !== '/session/refresh'
    ) {
      router.push(nextUrl);
    } else {
      router.push({ name: 'DashboardPage' });
    }
  }).catch((error) => {
    let { message } = error;
    if (message === undefined) {
      message = error;
    }
    if (error === undefined) {
      message = 'Não foi possível completar sua solicitação. Tente novamente mais tarde. (x002)';
    }

    notify({
      duration: 5000,
      type: 'error',
      title: 'Ooops',
      text: message,
    });
    isLoading.value = false;
  });
};

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_notifications = _resolveComponent("notifications")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_RouterLink, { to: { name: 'HomePage' } }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(SmallLogoComponent))
                  ]),
                  _: 1
                })
              ]),
              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "block" }, [
                _createElementVNode("p", { class: "title has-text-primary" }, " Fazer login "),
                _createElementVNode("p", null, "Utilize seu CPF e senha para acessar sua conta.")
              ], -1)),
              (errorMessage.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(errorMessage.value), 1))
                : _createCommentVNode("", true),
              _cache[11] || (_cache[11] = _createElementVNode("hr", null, null, -1)),
              _createElementVNode("form", {
                class: "mt-5",
                onKeydown: _cache[6] || (_cache[6] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"])),
                onSubmit: _withModifiers(submitForm, ["prevent"])
              }, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((documentType).value = $event)),
                  type: "hidden",
                  class: "input",
                  autocomplete: "document-type",
                  readonly: ""
                }, null, 512), [
                  [_vModelText, documentType.value]
                ]),
                (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(CPFInput)), {
                  modelValue: document.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((document).value = $event)),
                  "input-id": "documentId",
                  "is-loading": isLoading.value,
                  "input-required": true,
                  "input-label": "CPF",
                  "input-placeholder": "Utilize apenas números",
                  "input-error": documentError.value,
                  "onUpdate:error": _cache[2] || (_cache[2] = (value) => { documentError.value = value; })
                }, null, 40, ["modelValue", "is-loading", "input-error"])),
                (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(PasswordInput)), {
                  modelValue: password.value,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((password).value = $event)),
                  "input-id": "passwordId",
                  "is-loading": isLoading.value,
                  "input-required": true,
                  "input-label": "Senha",
                  "input-placeholder": "Informe sua senha",
                  "input-error": passwordError.value,
                  "show-password": showPassword.value,
                  "onUpdate:error": _cache[4] || (_cache[4] = (value) => { passwordError.value = value; }),
                  "onUpdate:showPassword": _cache[5] || (_cache[5] = (value) => showPassword.value = value)
                }, null, 40, ["modelValue", "is-loading", "input-error", "show-password"])),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_RouterLink, {
                      to: { name: 'SessionRecoverPage' },
                      class: "has-text-primary"
                    }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode(" Recuperar minha senha ")
                      ])),
                      _: 1
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("button", {
                      class: _normalizeClass(['box', 'button', 'is-primary', {
                      'is-loading': isLoading.value,
                    }]),
                      type: "submit",
                      disabled: !submitEnabled.value || isLoading.value
                    }, " Fazer login ", 10, _hoisted_12)
                  ])
                ])
              ], 32),
              _cache[12] || (_cache[12] = _createElementVNode("hr", null, null, -1)),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("p", null, [
                  _cache[9] || (_cache[9] = _createTextVNode(" Ainda não tem uma conta? ")),
                  _createVNode(_component_RouterLink, {
                    to: { name: 'SessionSignupPage' },
                    class: "has-text-primary"
                  }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createElementVNode("span", { class: "whitespace: nowrap" }, "Cadastre-se", -1)
                    ])),
                    _: 1
                  })
                ])
              ])
            ])
          ])
        ]),
        _cache[13] || (_cache[13] = _createStaticVNode("<div class=\"column is-flex is-align-items-center is-justify-content-center\" style=\"height:100vh;\" data-v-73a06c3b><div class=\"columns is-multiline\" data-v-73a06c3b><div class=\"column is-full\" data-v-73a06c3b><p class=\"title\" data-v-73a06c3b> Maximize o impacto do video e multiplique sua taxa de conversão </p></div><div class=\"column is-full\" data-v-73a06c3b><p class=\"subtitle\" data-v-73a06c3b> Atinja conversões 10x superiores ou mais que seu e-commerce: Descubra o poder da transmissão ao vivo com a Live Link </p></div><div class=\"column is-full\" data-v-73a06c3b><figure class=\"image is-4by3\" data-v-73a06c3b><img alt=\"Placeholder image\" src=\"" + _imports_0 + "\" data-v-73a06c3b></figure></div></div></div>", 1))
      ])
    ]),
    _createVNode(_component_notifications, { classes: "toast-notification" })
  ], 64))
}
}

})
<template>
  <RouterView
    v-if="!isLoading"
    :key="routeKey"
    :products="products"
    :merchant-uuid="merchantUuid"
    @update:products="resetFields"
  />
  <LoadingComponent
    v-if="isLoading"
    :full-screen="true"
  />
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  onBeforeMount,
} from 'vue';
import { useRouter } from 'vue-router';
import useProductStore from '@/store/externalLink/product';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';

const router = useRouter();

const {
  productsDoSearch,
} = useProductStore();

/*
  Data
*/
const products = ref([]);
const isLoading = ref(true);

/*
  Computed
*/
const routeKey = computed(() => router.fullPath);
const merchantUuid = computed(() => router.currentRoute.value.params.uuid);

/*
  Methods
*/
const resetFields = async () => {
  isLoading.value = true;
  await productsDoSearch({
    merchantUuid: merchantUuid.value,
  }, true).then((response) => {
    if (response !== undefined && response.data.length > 0) {
      products.value = response.data.sort((a, b) => {
        if (a.title > b.title) {
          return 1;
        }
        if (a.title < b.title) {
          return -1;
        }

        return 0;
      });
    }
  }).catch((error) => {
    console.log(error);
  }).finally(() => {
    isLoading.value = false;
  });
};

/*
  Hooks
*/
onBeforeMount(async () => {
  resetFields();
});
</script>

<style lang="scss" scoped>
</style>

<template>
  <nav
    ref="topMenu"
    class="navbar is-fixed-top is-white has-background-white"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <RouterLink
        :to="{ name: 'HomePage' }"
        class="navbar-item"
        aria-label="navbar main logo"
        @click="goHome"
      >
        <SmallLogoComponent />
      </RouterLink>

      <a
        href="#top"
        class="navbar-burger"
        :class="{ 'is-active': isActive }"
        aria-label="Toggle Menu"
        @click="toggleMenu"
        @keyup.enter="toggleMenu"
      >
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </a>
    </div>
    <div
      :class="['navbar-menu', { 'is-active': isActive }]"
    >
      <div class="navbar-start">
        <!-- <RouterLink
          :class="['navbar-item', {
            'is-active': router.currentRoute.value.fullPath.includes('/dashboard/')
          }]"
          :to="{
            name: 'HomePage',
          }"
          @click="closeMenu"
        >
          Página Inicial
        </RouterLink> -->
        <!-- <RouterLink
          :class="['navbar-item', {
            'is-active': router.currentRoute.value.fullPath.includes('/merchants/')
          }]"
          :to="{
            name: 'MyMerchantsPage',
          }"
          @click="closeMenu"
        >
          Minhas Lojas
        </RouterLink> -->
        <!-- <span
          :class="['navbar-item has-text-grey-light', {
            'is-active': router.currentRoute.value.fullPath.includes('/merchants/')
          }]"
        >
          Minhas Compras
        </span> -->
        <!-- <span
          :class="['navbar-item has-text-grey-light', {
            'is-active': router.currentRoute.value.fullPath.includes('/merchants/')
          }]"
        >
          Favoritos
        </span> -->
      </div>
      <hr>
      <div :class="['navbar-end', { 'is-hidden': !isAuthenticated() }]">
        <div
          :class="['navbar-item', {
            'is-active': router.currentRoute.value.fullPath.includes('/profile/')
          }]"
        >
          <div class="buttons">
            <button
              class="button is-white"
              type="button"
              :disabled="submitDisabled"
              @click="myProfile"
            >
              <font-awesome-icon
                class="fa-lg mr-2"
                :icon="{ prefix: 'fa', iconName: 'user-circle' }"
              />
              Minha conta
            </button>
            <button
              class="button is-hidden-tablet is-outlined"
              type="button"
              @click="logout"
            >
              Logout
              <font-awesome-icon
                class="fa-lg ml-2"
                :icon="{ prefix: 'fa', iconName: 'sign-out' }"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <LoadingComponent v-if="isLoading" />
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import AuthStore from '@/store/auth';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';
import SmallLogoComponent from '@/components/shared/SmallLogoComponent.vue';

const router = useRouter();
const {
  isAuthenticated,
  authDoLogout,
} = AuthStore();

/*
  Data
*/
const isActive = ref(false);
const isLoading = ref(false);
const submitDisabled = ref(false);
const topMenu = ref('');

/*
  Methods
*/
const logout = async () => {
  isLoading.value = true;
  submitDisabled.value = true;
  authDoLogout().then(() => {
    router.push({ name: 'SessionLoginPage' });
  }).catch((error) => {
    let { message } = error;
    if (message === undefined) {
      message = error;
    }
    if (error === undefined) {
      message = 'Não foi possível completar sua solicitação. Tente novamente mais tarde. (x002)';
    }

    isLoading.value = false;
    submitDisabled.value = false;
  }).finally(() => {
    window.location.reload();
  });
};
const goHome = () => {
  router.push({
    name: 'DashboardPage',
  });
  // window.open('/', '_self');
};
// const closeMenu = () => {
//   isActive.value = false;
// };
const myProfile = () => {
  // isLoading.value = true;
  router.push({
    name: 'UserProfileManagerPage',
  });
  // setTimeout(() => {
  //   isLoading.value = false;
  // }, 5000);
};
// const switchAccount = async () => {
//   router.push({ name: 'SessionSwitchAccountPage' });
// };
const toggleMenu = () => {
  isActive.value = !isActive.value;
};

/*
  Hooks
*/
onMounted(() => {
  // topMenu.value.addEventListener('click', closeMenu);
});

</script>

<style scoped>
  nav {
    min-height: 3.5rem;
    height: 3.5rem;
  }
  .navbar-brand {
    min-height: 3.5rem;
  }
</style>

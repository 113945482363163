<template>
  <RouterView
    v-if="!isLoading"
    :account="account"
    :scheduled-reports-metadata="scheduledReportsMetadata"
    :reports-metadata="reportsMetadata"
    :report-dates="reportDates"
    :total-messages="totalMessages"
    :total-dates="totalDates"
    @refresh:scheduled-live-reports="loadScheduledReports"
  />
  <LoadingComponent
    v-if="isLoading"
    :full-screen="true"
  />
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  onBeforeMount,
} from 'vue';
import { useRouter } from 'vue-router';
import useLiveReportMetaStore from '@/store/merchant/liveReportMeta';
import Metadata from '@/models/livereport/metadata';
import ScheduledMetadata from '@/models/livereport/scheduledMetadata';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';

const {
  getAllReports,
  getAllScheduledReports,
} = useLiveReportMetaStore();
const router = useRouter();

/*
  Computed
*/
const account = computed(() => router.currentRoute.value.params.account);

/*
  Data
*/
const totalMessages = ref(0);
const totalDates = ref(0);
const isLoading = ref(true);
const reportsMetadata = ref([] as Metadata[]);
const scheduledReportsMetadata = ref([] as ScheduledMetadata[]);
const reportDates = ref([]);

/*
  Methods
*/
const getTotalDates = (_dates: Date[]) => _dates.length;
const getTotalMessages = (
  _reportsMetadata: Metadata[],
) => _reportsMetadata.reduce((acc, report) => {
  const messages = report.types.find((type) => type.eType === 'msg');
  return acc + (messages ? messages.count : 0);
}, 0);
const getAllDates = () => {
  const allDates = reportsMetadata.value.reduce((acc, report) => acc.concat(report.dates), []);
  const dates = Array.from(new Set(allDates));
  const utcDates = dates.map((dateString: string) => {
    const [year, month, day] = dateString.split('-').map(Number); // Convert parts to numbers
    return new Date(year, month - 1, day); // month - 1 to make it zero-based
  });
  return utcDates.sort((a, b) => b - a);
};
const loadReports = async () => {
  getAllReports(account.value).then(({ data }) => {
    if (!data) {
      return;
    }
    reportsMetadata.value = data;
    totalMessages.value = getTotalMessages(reportsMetadata.value);
    reportDates.value = getAllDates();
    totalDates.value = getTotalDates(reportDates.value);
  }).catch((error) => {
    if (error.response && error.response.status === 403) {
      router.push({ name: 'NotFoundPage' });
      return;
    }
    console.error(error);
    alert('Erro ao carregar relatórios. Recarregue a página e tente novamente.');
    isLoading.value = false;
  });
};
const loadScheduledReports = async () => {
  getAllScheduledReports(account.value).then((response) => {
    if (!response) {
      return;
    }
    // Filter and not show all reports that have scheduled date in the past
    scheduledReportsMetadata.value = response.filter(
      (report) => new Date(report.scheduledDate) > new Date(),
    );
    scheduledReportsMetadata.value = response.reverse();
  }).catch((error) => {
    if (error.response && error.response.status === 403) {
      router.push({ name: 'NotFoundPage' });
      return;
    }
    console.error(error);
    alert('Erro ao carregar relatórios agendados. Recarregue a página e tente novamente.');
    isLoading.value = false;
  });
};
/*
  Hooks
*/
onBeforeMount(async () => {
  await loadReports();
  await loadScheduledReports();
  isLoading.value = false;
});
</script>

<style scoped lang="scss">
</style>

class InstagramLiveUser {
  id: string;

  mediaId: string;

  accountId: string;

  userId: string;

  userName: string;

  timestamp: Date;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor({
    id,
    mediaId,
    accountId,
    userId,
    userName,
    timestamp,
  }: {
    id: string,
    mediaId: string,
    accountId: string,
    userId: string,
    userName: string,
    timestamp: Date
  }) {
    this.id = id;
    this.mediaId = mediaId;
    this.accountId = accountId;
    this.userId = userId;
    this.userName = userName;
    this.timestamp = timestamp;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromApiResponse(columns: string[], rows: any[][]): InstagramLiveUser[] {
    if (rows.length === 0) {
      return [];
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return rows.map((row: any[]) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const v: any = {};
      columns.forEach((column, index) => {
        const camelCaseKey = column.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
        if (row[index] && (camelCaseKey === 'timestamp' || camelCaseKey === 'createdAt')) {
          v[camelCaseKey] = new Date(row[index]);
        } else {
          v[camelCaseKey] = row[index];
        }
      });
      return new InstagramLiveUser(v);
    });
  }
}

export default InstagramLiveUser;

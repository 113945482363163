import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, computed, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import usePluginStore from '@/store/plugin/plugin';
import InstagramLiveStatus from '@/models/plugin/instagramLiveStatus';


export default /*@__PURE__*/_defineComponent({
  __name: 'PluginLiveReportLayout',
  setup(__props) {

const router = useRouter();

const {
  pluginAccountLiveStatus,
} = usePluginStore();

/*
  Data
*/
const initialLimitMessages = ref(2000);
const initialPageMessages = ref(1);
const initialLimitQuestions = ref(2000);
const initialPageQuestions = ref(1);
const initialLimitUsers = ref(2000);
const initialPageUsers = ref(1);
const instagramLiveStatus = ref({} as InstagramLiveStatus);

/*
  Computed
*/
const pluginId = computed(() => router.currentRoute.value.params.pluginId);
const pluginType = computed(() => router.currentRoute.value.params.pluginType);
const accountId = computed(() => router.currentRoute.value.params.accountId);
const mediaId = computed(() => router.currentRoute.value.params.mediaId);

/*
  Methods
*/
const loadAccountLiveStatus = async () => {
  try {
    const res = await pluginAccountLiveStatus({
      pluginId: `${pluginId.value}`,
      pluginType: `${pluginType.value}`,
    });

    if (!res || !Array.isArray(res.data) || res.data.length === 0) {
      instagramLiveStatus.value = {} as InstagramLiveStatus;
      return;
    }

    console.log('res.data', res.data);

    const { data } = res;
    [instagramLiveStatus.value] = data;
  } catch (err) {
    console.error('Error loading account live status:', err);
    instagramLiveStatus.value = {} as InstagramLiveStatus;
  }
};

/*
  Hooks
*/
onBeforeMount(async () => {
  await loadAccountLiveStatus();
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createBlock(_component_RouterView, {
    "plugin-id": pluginId.value,
    "plugin-type": pluginType.value,
    "account-id": accountId.value,
    "media-id": mediaId.value,
    "instagram-live-status": instagramLiveStatus.value,
    "initial-page-messages": initialPageMessages.value,
    "initial-limit-messages": initialLimitMessages.value,
    "initial-page-questions": initialPageQuestions.value,
    "initial-limit-questions": initialLimitQuestions.value,
    "initial-page-users": initialPageUsers.value,
    "initial-limit-users": initialLimitUsers.value
  }, null, 8, ["plugin-id", "plugin-type", "account-id", "media-id", "instagram-live-status", "initial-page-messages", "initial-limit-messages", "initial-page-questions", "initial-limit-questions", "initial-page-users", "initial-limit-users"]))
}
}

})
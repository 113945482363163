import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { ref, computed, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import usePluginStore from '@/store/plugin/plugin';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';
import InstagramLiveMetadata from '@/models/plugin/instagramLiveMetadata';
import Pagination from '@/models/pagination';


export default /*@__PURE__*/_defineComponent({
  __name: 'PluginLivesLayout',
  setup(__props) {

const {
  pluginGetLiveMetadatas,
} = usePluginStore();
const router = useRouter();

/*
  Computed
*/
const pluginId = computed(() => router.currentRoute.value.params.pluginId);
const pluginType = computed(() => router.currentRoute.value.params.pluginType);

/*
  Data
*/
const isLoading = ref(true);
const metadatas = ref([] as InstagramLiveMetadata[]);
const metadatasPagination = ref({} as Pagination);
const orderBy = ref('createdAt');
const limit = ref(10);
const order = ref(-1);
const page = ref(1);

/*
  Methods
*/
const loadPluginLiveMetadatas = async () => {
  isLoading.value = true;

  interface InstagramLiveMetadataResponse {
    data: InstagramLiveMetadata[];
    pagination: Pagination;
  }

  try {
    const response = await pluginGetLiveMetadatas({
      pluginId: `${pluginId.value}`,
      pluginType: `${pluginType.value}`,
      page: page.value,
      limit: limit.value,
      orderBy: orderBy.value,
      order: order.value,
    }) as InstagramLiveMetadataResponse;

    isLoading.value = false;

    if (!response) {
      return;
    }

    metadatas.value = response.data;
    metadatasPagination.value = response.pagination;
  } catch (error) {
    console.error('Error', error);
    isLoading.value = false;
  }
};

/*
  Hooks
*/
onBeforeMount(async () => {
  await loadPluginLiveMetadatas();
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!isLoading.value)
      ? (_openBlock(), _createBlock(_component_RouterView, {
          key: 0,
          "plugin-id": pluginId.value,
          "plugin-type": pluginType.value,
          metadatas: metadatas.value,
          "metadatas-pagination": metadatasPagination.value
        }, null, 8, ["plugin-id", "plugin-type", "metadatas", "metadatas-pagination"]))
      : _createCommentVNode("", true),
    (isLoading.value)
      ? (_openBlock(), _createBlock(LoadingComponent, {
          key: 1,
          "full-screen": true
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})
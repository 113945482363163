<template>
  <PageHeader
    :is-loading="isLoading"
    title="Link da bio"
  />
  <main
    v-if="!isLoading"
    class="main has-navbar-fixed-top"
  >
    <div v-if="!!myProfile.username && !!myBio.id">
      <RouterView
        :username="myProfile.username"
        :my-bio="myBio"
        @update:my-bio="(value) => myBio = value"
      />
    </div>
    <section
      v-else
      class="section"
    >
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-full">
            <p class="title is-4">
              Criar link da bio
            </p>
            <p class="subtitle is-size-6">
              Crie seu link personalizado, totalmente customizável, e utilize em suas redes sociais.
            </p>
          </div>
          <div class="column is-full">
            <div class="box">
              <p class="title is-6">
                Nome do perfil <span class="tag ml-2">obrigatório</span>
              </p>
              <p class="subtitle is-7">
                Você terá uma url exclusiva para compartilhar com seu público
              </p>
              <component
                :is="UsernameInput"
                v-model="username"
                input-id="username"
                :is-loading="isLoading"
                :input-required="false"
                input-label=""
                input-placeholder="por ex: leandroxbr"
                :input-min-length="3"
                :input-min-words="1"
                :input-max-length="30"
                input-min-words-error="No mínimo 3 caracteres"
                input-auto-complete="username"
                :input-error="usernameError"
                @update:input-error="(value) => usernameError = value"
              />
              <p class="help">
                <!-- eslint-disable-next-line max-len -->
                {{ username.length }} de 30 caracteres. Você pode usar letras, números, pontos finais e sublinhados.
              </P>
            </div>
          </div>
          <div class="column is-full has-text-right">
            <button
              type="button"
              class="button is-inverted mr-2"
              style="background-color: transparent; border-color: transparent;"
              :disabled="isLoading"
              @click.prevent="router.push({ name: 'DashboardPage' })"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="button is-primary"
              :disabled="isLoading || username.length < 3"
              @click.prevent="setUsername"
            >
              Criar link da bio
            </button>
          </div>
        </div>
      </div>
    </section>
  </main>
  <LoadingComponent
    v-if="isLoading"
  />
  <notifications classes="toast-notification" />
</template>

<script setup lang="ts">
import {
  ref,
  onBeforeMount,
} from 'vue';
import { useRouter } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';
import PageHeader from '@/components/header/PageHeader.vue';
import useUserStore from '@/store/user';
import User from '@/models/user';
import Profile from '@/models/user/bio/profile';
import Style from '@/models/user/bio/style';
import UserBio from '@/models/user/bio/userBio';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';
import UsernameInput from '@/components/form/UsernameInput.vue';

const router = useRouter();
const {
  usersDoLoadBio,
  usersDoGetMyUser,
  usersDoUpdateMyUser,
} = useUserStore();

/*
  Data
*/
const dataLoaded = ref(false);
const isLoading = ref(true);
const myProfile = ref({} as User);
const myBio = ref({});
const username = ref('');
const usernameError = ref('');

/*
  Methods
*/
const redirectDashboard = ((error: string) => router.push({
  name: 'DashboardPage',
  query: {
    message: 'Não foi possível carregar a página. Tente novamente em instantes.',
    detail: error,
    type: 'error',
  },
}));
const load = async () => {
  isLoading.value = true;
  await usersDoGetMyUser()
    .then((response) => {
      if (response === undefined) {
        throw new Error('O perfil que você tento acessar está indisponível no momento. Recarregue a página e tente novamente');
      }
      myProfile.value = response;
    }).catch((error) => redirectDashboard(error.toString()));

  await usersDoLoadBio(myProfile.value.username)
    .then((response) => {
      if (response === undefined) {
        myBio.value = new UserBio({
          username: myProfile.value.username,
          style: new Style(),
          live: null,
          profile: new Profile(),
          links: [],
        });
      } else {
        myBio.value = response;
      }
    }).catch((error) => {
      console.log(error);
      // redirectDashboard(error.toString());
    });

  isLoading.value = false;
  dataLoaded.value = true;
};
const submitForm = async (payload = {}) => {
  isLoading.value = true;
  await usersDoUpdateMyUser(payload).then(async (success) => {
    if (success === false) {
      throw new Error('Não foi possível completar sua solicitação. Tente novamente mais tarde.');
    }
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }).catch((error) => {
    isLoading.value = false;
    let message;
    if (error.response?.data?.error) {
      message = error.response?.data?.error;
    }
    if (message === undefined) {
      message = error;
    }
    if (error === undefined) {
      message = 'Não foi possível completar sua solicitação. Tente novamente mais tarde. (x002)';
    }
    notify({
      duration: 5000,
      type: 'error',
      title: 'Ooops',
      text: message,
    });
  });
};
const setUsername = async () => {
  username.value = username.value.trim().toLowerCase();
  await submitForm({
    username: `@${username.value}`,
  });
};
/*
  Hooks
*/
onBeforeMount(async () => {
  await load();
  dataLoaded.value = true;
});
</script>

<style lang="scss" scoped>
</style>

import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref } from "vue"

const _hoisted_1 = { class: "has-text-left is-white mb-0" }
const _hoisted_2 = { class: "icon-text" }
const _hoisted_3 = { class: "fa-lg" }
const _hoisted_4 = { key: 0 }


import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'NavigateBackMenu',
  props: {
  to: {
    type: String,
    required: true,
  },
  params: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  query: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  text: {
    type: String,
    required: false,
    default: '',
  },
  icon: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const router = useRouter();

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      type: "button",
      class: "button is-black is-inverted",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (props.params ?
        _unref(router).push({ name: props.to, params: props.params, query: props.query })
        : _unref(router).push({ name: props.to })))
    }, [
      _createElementVNode("span", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_font_awesome_icon, {
            icon: props.icon,
            class: "fa-lg"
          }, null, 8, ["icon"])
        ]),
        (props.text)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(props.text), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

import useAuthStore from '@/store/auth';
import HomeHeader from '@/components/header/HomeHeader.vue';
import UserHeader from '@/components/header/UserHeader.vue';
import MerchantHeader from '@/components/header/MerchantHeader.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppHeader',
  setup(__props) {

const { isStoreModeCustomer, isStoreModeMerchant } = useAuthStore();

return (_ctx: any,_cache: any) => {
  return (_unref(isStoreModeCustomer)())
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(UserHeader)
      ]))
    : (_unref(isStoreModeMerchant)())
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(MerchantHeader)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(HomeHeader)
        ]))
}
}

})
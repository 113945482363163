import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'GuestLayout',
  setup(__props) {

// import HomeHeader from '@/components/header/HomeHeader.vue';

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createVNode(_component_RouterView)
  ]))
}
}

})
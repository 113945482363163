import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const router = useRouter();

const layoutComponent = computed(() => {
  const { meta: { layout } } = router.currentRoute.value;
  return layout || 'GuestLayout';
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(layoutComponent.value)))
}
}

})
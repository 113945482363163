class InstagramLiveStatus {
  id: string;

  likeCount: string;

  commentsCount: string;

  timestamp: Date;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor({
    id,
    likeCount,
    commentsCount,
    timestamp,
  }: {
    id: string,
    likeCount: string,
    commentsCount: string,
    timestamp: Date
  }) {
    this.id = id;
    this.likeCount = likeCount;
    this.commentsCount = commentsCount;
    this.timestamp = timestamp;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromApiResponse(columns: string[], rows: any[][]): InstagramLiveStatus[] {
    if (rows.length === 0) {
      return [];
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return rows.map((row: any[]) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const v: any = {};
      columns.forEach((column, index) => {
        const camelCaseKey = column.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
        if (row[index] && (camelCaseKey === 'timestamp' || camelCaseKey === 'createdAt')) {
          v[camelCaseKey] = new Date(row[index]);
        } else {
          v[camelCaseKey] = row[index];
        }
      });
      return new InstagramLiveStatus(v);
    });
  }
}

export default InstagramLiveStatus;

<template>
  <nav
    v-if="!props.isLoading"
    ref="topMenu"
    class="navbar is-fixed-top is-white has-background-white"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand is-flex is-justify-content-space-between">
      <div class="navbar-item p-0">
        <RouterLink
          :to="{ name: 'DashboardPage' }"
          class="navbar-item"
          aria-label="navbar main logo"
        >
          <figure class="image is-flex is-align-items-center">
            <img
              src="@/assets/logo.png"
              alt="logo"
              style="width: 29px;"
            >
          </figure>
        </RouterLink>
        <p class="is-size-5">
          {{ props.title }}
        </p>
      </div>
      <div class="navbar-item is-hidden-desktop p-0">
        <slot name="content" />
        <NavigateBackMenu
          v-if="!$slots.content"
          class="navbar-item has-text-right is-transparent"
          :icon="props.icon"
          :to="props.to"
          :params="props.params"
          :query="props.query"
          :text="props.text"
        />
      </div>
    </div>
    <div class="navbar-menu">
      <div class="navbar-end">
        <div class="navbar-item p-0">
          <slot name="content" />
          <NavigateBackMenu
            v-if="!$slots.content"
            class="navbar-item has-text-right is-transparent"
            :icon="props.icon"
            :to="props.to"
            :params="props.params"
            :query="props.query"
            :text="props.text"
          />
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup lang='ts'>
import {
  defineProps,
} from 'vue';
import NavigateBackMenu from '@/components/menu/NavigateBackMenu.vue';

/*
  Props
*/
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: true,
  },
  to: {
    type: String,
    required: false,
    default: 'DashboardPage',
  },
  params: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  query: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  text: {
    type: String,
    required: false,
    default: '',
  },
  icon: {
    type: String,
    required: false,
    default: 'close',
  },
});
</script>

<style lang="scss">
  .navbar-item img {
    max-height: none;
  }
</style>

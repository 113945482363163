<template>
  <RouterView
    :initial-page="initialPage"
    :initial-limit="initialLimit"
  />
  <LoadingComponent
    v-if="isLoading"
    :full-screen="true"
  />
</template>

<script setup lang="ts">
import {
  ref,
  onBeforeMount,
} from 'vue';
import { useRoute } from 'vue-router';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';

const router = useRoute();

/*
  Data
*/
const isLoading = ref(true);
const initialPage = ref(1);
const initialLimit = ref(10);

/*
  Hooks
*/
onBeforeMount(() => {
  initialPage.value = router.query.page;
  initialLimit.value = router.query.limit;
  isLoading.value = false;
});
</script>

<style scoped lang="scss">
</style>

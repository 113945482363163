import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "label" }
const _hoisted_2 = {
  key: 0,
  class: "help"
}
const _hoisted_3 = { style: {"font-weight":"normal"} }

import {
  ref,
  watch,
} from 'vue';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

/*
  Props
*/

export default /*@__PURE__*/_defineComponent({
  __name: 'TextArea',
  props: {
  label: {
    type: String,
    required: false,
    default: '',
  },
  subLabel: {
    type: String,
    required: false,
    default: '',
  },
  theme: {
    type: String,
    required: false,
    default: 'snow',
  },
  isSimple: {
    type: Boolean,
    required: false,
    default: false,
  },
  readOnly: {
    type: Boolean,
    required: false,
    default: true,
  },
  modelValue: {
    type: String,
    required: true,
  },
  inputId: {
    type: String,
    required: true,
  },
  inputPlaceholder: {
    type: String,
    required: false,
    default: '',
  },
},
  emits: [
  'update:modelValue',
],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

/*
  Data
*/
const quillEditor = ref(null);
const toolbarContent = ref([
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ size: ['small', false, 'large', 'huge'] }],
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote', 'code-block'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ align: [] }],
  [{ color: [] }, { background: [] }],
  ['link'], // ['link', 'image', 'video'],
  // [{ header: 1 }, { header: 2 }],
  // [{ script: 'sub' }, { script: 'super' }],
  // [{ direction: 'rtl' }],
  // [{ font: [] }],
  // ['clean'],
]);
const editorOptions = ref({
  theme: 'bubble',
  contentType: 'html',
  // debug: 'info',
  // enable: props.enable,
  placeholder: props.readOnly ? '' : props.inputPlaceholder,
  readOnly: props.readOnly,
  modules: {
    toolbar: props.isSimple ? 'minimal' : toolbarContent.value,
  },
});

const onTextChange = () => {
  emit('update:modelValue', quillEditor.value.getHTML());
};

watch(props, (newValue) => {
  if (quillEditor.value.getHTML() === props.modelValue) {
    return;
  }
  quillEditor.value.setContents(props.modelValue, 'html');
  editorOptions.value.readOnly = newValue.readOnly;
});

return (_ctx: any,_cache: any) => {
  const _component_QuillEditor = _resolveComponent("QuillEditor")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['rich-text-editor', {
      'is-disabled': props.readOnly,
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createTextVNode(_toDisplayString(props.label) + " ", 1),
      (props.subLabel)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(props.subLabel), 1))
        : _createCommentVNode("", true),
      _createElementVNode("p", _hoisted_3, [
        _createVNode(_component_QuillEditor, {
          id: props.inputId,
          ref_key: "quillEditor",
          ref: quillEditor,
          class: "quill-editor-limited-height",
          theme: props.theme,
          "content-type": "html",
          options: editorOptions.value,
          onKeyup: onTextChange
        }, null, 8, ["id", "theme", "options"])
      ])
    ])
  ], 2))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import {
  ref,
  onBeforeMount,
} from 'vue';
import { useRoute } from 'vue-router';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'MerchantExternalLiveReportAdminLayout',
  setup(__props) {

const router = useRoute();

/*
  Data
*/
const isLoading = ref(true);
const initialPage = ref(1);
const initialLimit = ref(10);

/*
  Hooks
*/
onBeforeMount(() => {
  initialPage.value = router.query.page;
  initialLimit.value = router.query.limit;
  isLoading.value = false;
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_RouterView, {
      "initial-page": initialPage.value,
      "initial-limit": initialLimit.value
    }, null, 8, ["initial-page", "initial-limit"]),
    (isLoading.value)
      ? (_openBlock(), _createBlock(LoadingComponent, {
          key: 0,
          "full-screen": true
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})
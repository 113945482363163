<template>
  <PageHeader
    :is-loading="isLoading"
    title="Contas conectadas"
  />
  <main
    v-if="!isLoading"
    class="main has-navbar-fixed-top"
  >
    <RouterView
      :plugins="plugins"
      @update:plugins="(value) => newPlugins = value"
      @delete:plugin="(value) => deletePlugin(value)"
    />
  </main>
  <LoadingComponent
    v-if="isLoading"
  />
  <notifications classes="toast-notification" />
</template>

<script setup lang="ts">
import {
  ref,
  watch,
  onBeforeMount,
} from 'vue';
import { useRouter } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';
import usePluginStore from '@/store/plugin/plugin';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';
import PageHeader from '@/components/header/PageHeader.vue';

const router = useRouter();
const {
  pluginsDoGetMy,
  pluginsDoDelete,
} = usePluginStore();

/*
  Data
*/
const dataLoaded = ref(false);
const isLoading = ref(true);
const plugins = ref([] as Plugin[]);
const newPlugins = ref([] as Plugin[]);

/*
  Methods
*/
const deletePlugin = async (plugin: Plugin) => {
  await pluginsDoDelete(plugin.id)
    .then(() => {
      notify({
        type: 'success',
        title: 'Sucesso',
        text: 'Plugin removido com sucesso',
      });
      plugins.value = plugins.value.filter((p) => p.id !== plugin.id);
    }).catch((error) => {
      console.log('error', error);
      notify({
        type: 'error',
        title: 'Erro',
        text: 'Não foi possível remover o plugin',
      });
    });
};
const redirectDashboard = ((error: string) => router.push({
  name: 'DashboardPage',
  query: {
    message: 'Não foi possível carregar a página. Tente novamente em instantes.',
    detail: error,
    type: 'error',
  },
}));
const load = async () => {
  isLoading.value = true;
  await pluginsDoGetMy()
    .then((response) => {
      if (response !== undefined) {
        plugins.value = response.data;
      }
    }).catch((error) => {
      console.log('error', error);
      redirectDashboard(error.toString());
    });

  isLoading.value = false;
  dataLoaded.value = true;
};

/*
  Hooks
*/
onBeforeMount(async () => {
  await load();
  dataLoaded.value = true;
});
watch(newPlugins, () => {
  load();
});
</script>

<style lang="scss" scoped>
</style>

<template>
  <RouterView
    :stream="myStream"
    :brand-products="brandProducts"
    :products="products"
    @update:stream="(value) => updateStream(value)"
  />
  <LoadingComponent
    v-if="isLoading"
    :full-screen="false"
  />
  <notifications classes="toast-notification" />
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  onBeforeMount,
} from 'vue';
import { useRouter } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';
import Stream from '@/models/stream';
import Product from '@/models/externalLink/product';
import useStreamStore from '@/store/stream';
import useProductsStore from '@/store/externalLink/product';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';

const router = useRouter();
const {
  productsDoSearch,
} = useProductsStore();
const {
  streamsDoGetMy,
  streamsDoUpdateMyStream,
} = useStreamStore();

/*
  Data
*/
const brandProducts = ref([]);
const products = ref([]);
const myStream = ref({} as Stream);
const isLoading = ref(true);

/*
  Computed
*/
const streamUuid = computed(() => router.currentRoute.value.params.uuid);

/*
  Methods
*/
const loadBrandProducts = async (stream: Stream) => {
  await productsDoSearch({ merchantUuid: stream.merchantUuid })
    .then((response) => {
      if (response !== undefined) {
        brandProducts.value = response.data;

        const sPHash = stream.productsUuid?.reduce((acc, cur) => {
          acc[cur] = true;
          return acc;
        }, {});

        if (sPHash === undefined) {
          return;
        }
        products.value = brandProducts.value.filter((p) => sPHash[p.uuid] !== undefined);
        products.value = products.value.map((p) => new Product(p));
      }
    }).catch((error) => {
      console.error(error);
    });
};
const load = async () => {
  isLoading.value = true;
  await streamsDoGetMy({ uuid: streamUuid.value })
    .then(async (response) => {
      if (response.data.length > 0) {
        [myStream.value] = response.data.filter((stream) => stream.uuid === streamUuid.value);
        await loadBrandProducts(myStream.value);
      }
    }).catch((error) => {
      console.error(error);
    }).finally(() => {
      isLoading.value = false;
    });
  isLoading.value = false;
};
const updateStream = async (value) => {
  const { productsUuid, activeProductsId, showProductsOnLanding } = value;
  isLoading.value = true;
  const response = await streamsDoUpdateMyStream({
    uuid: streamUuid.value,
    productsUuid,
    activeProductsId,
    showProductsOnLanding,
  });
  if (response === undefined) {
    notify({
      duration: 5000,
      type: 'error',
      title: 'Ooops',
      text: 'Não foi possível completar sua solicitação. Tente novamente mais tarde.',
    });
  } else {
    load();
  }
  isLoading.value = false;
};

/*
  Hooks
*/
onBeforeMount(async () => {
  load();
});
</script>

<style lang="scss" scoped>
</style>

import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "main has-navbar-fixed-top"
}

import {
  ref,
  watch,
  onBeforeMount,
} from 'vue';
import { useRouter } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';
import usePluginStore from '@/store/plugin/plugin';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';
import PageHeader from '@/components/header/PageHeader.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ManagerLayout',
  setup(__props) {

const router = useRouter();
const {
  pluginsDoGetMy,
  pluginsDoDelete,
} = usePluginStore();

/*
  Data
*/
const dataLoaded = ref(false);
const isLoading = ref(true);
const plugins = ref([] as Plugin[]);
const newPlugins = ref([] as Plugin[]);

/*
  Methods
*/
const deletePlugin = async (plugin: Plugin) => {
  await pluginsDoDelete(plugin.id)
    .then(() => {
      notify({
        type: 'success',
        title: 'Sucesso',
        text: 'Plugin removido com sucesso',
      });
      plugins.value = plugins.value.filter((p) => p.id !== plugin.id);
    }).catch((error) => {
      console.log('error', error);
      notify({
        type: 'error',
        title: 'Erro',
        text: 'Não foi possível remover o plugin',
      });
    });
};
const redirectDashboard = ((error: string) => router.push({
  name: 'DashboardPage',
  query: {
    message: 'Não foi possível carregar a página. Tente novamente em instantes.',
    detail: error,
    type: 'error',
  },
}));
const load = async () => {
  isLoading.value = true;
  await pluginsDoGetMy()
    .then((response) => {
      if (response !== undefined) {
        plugins.value = response.data;
      }
    }).catch((error) => {
      console.log('error', error);
      redirectDashboard(error.toString());
    });

  isLoading.value = false;
  dataLoaded.value = true;
};

/*
  Hooks
*/
onBeforeMount(async () => {
  await load();
  dataLoaded.value = true;
});
watch(newPlugins, () => {
  load();
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_notifications = _resolveComponent("notifications")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(PageHeader, {
      "is-loading": isLoading.value,
      title: "Contas conectadas"
    }, null, 8, ["is-loading"]),
    (!isLoading.value)
      ? (_openBlock(), _createElementBlock("main", _hoisted_1, [
          _createVNode(_component_RouterView, {
            plugins: plugins.value,
            "onUpdate:plugins": _cache[0] || (_cache[0] = (value) => newPlugins.value = value),
            "onDelete:plugin": _cache[1] || (_cache[1] = (value) => deletePlugin(value))
          }, null, 8, ["plugins"])
        ]))
      : _createCommentVNode("", true),
    (isLoading.value)
      ? (_openBlock(), _createBlock(LoadingComponent, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_notifications, { classes: "toast-notification" })
  ], 64))
}
}

})
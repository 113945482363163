<template lang="html">
  <teleport to="#loading-wrapper">
    <div
      :class="[
        fullScreen ? 'background-loader-full' : 'background-loader'
      ]"
      :style="[loaderStyle]"
    >
      <span class="mr-4 is-size-4">
        Loading
      </span>
      <div
        class="rounded-ball"
        :style="[ballStyle]"
      />
    </div>
  </teleport>
</template>

<script setup lang='ts'>
import { ref, defineProps } from 'vue';

const props = defineProps({
  background: {
    type: String,
    default: 'rgba(255,255,255,1)',
  },
  color: {
    type: String,
    default: '#35d0ba',
  },
  borderWidth: {
    type: Number,
    default: 5,
  },
  duration: {
    type: Number,
    default: 0.5,
  },
  size: {
    type: Number,
    default: 30,
  },
  fullScreen: {
    type: Boolean,
    default: false,
  },
});

const loaderStyle = ref(props.background);
const ballStyle = ref({
  borderColor: `${props.color} ${props.color} ${props.color} transparent`,
  borderWidth: `${props.borderWidth}px`,
  borderStyle: 'solid',
  animationDuration: `${props.duration}s`,
  width: `${props.size}px`,
  height: `${props.size}px`,
});
</script>

<style scoped lang="scss">
.background-loader {
    // background-color: rgba(255,255,255,1);
    padding-right: 24px;
    min-height: 10%;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
    transition: background 300ms ease-in 200ms;
}

@keyframes rotate {
    from {
        transform: rotate(360deg);
    }
    to {}
}
.rounded-ball {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    animation-name: rotate;
    animation-duration: 0.5s;
    animation-fill-mode: backwards;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.background-loader-full {
    background-color: #f0f4f7;
    padding-right: 24px;
    min-height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: background 300ms ease-in 200ms;
}
</style>

<!-- LoadingComponent

  <template>
    <LoadingComponent
      v-if="isLoading"
    />
  </template>

  <script setup lang="ts">
  import LoadingComponent from '@/components/shared/LoadingComponent.vue';

  const isLoading = ref(true);
-->

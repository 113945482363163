<template>
  <RouterView
    :plugin-id="pluginId"
    :plugin-type="pluginType"
    :account-id="accountId"
    :media-id="mediaId"
    :instagram-live-status="instagramLiveStatus"
    :initial-page-messages="initialPageMessages"
    :initial-limit-messages="initialLimitMessages"
    :initial-page-questions="initialPageQuestions"
    :initial-limit-questions="initialLimitQuestions"
    :initial-page-users="initialPageUsers"
    :initial-limit-users="initialLimitUsers"
  />
</template>

<script setup lang="ts">
import { ref, computed, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import usePluginStore from '@/store/plugin/plugin';
import InstagramLiveStatus from '@/models/plugin/instagramLiveStatus';

const router = useRouter();

const {
  pluginAccountLiveStatus,
} = usePluginStore();

/*
  Data
*/
const initialLimitMessages = ref(2000);
const initialPageMessages = ref(1);
const initialLimitQuestions = ref(2000);
const initialPageQuestions = ref(1);
const initialLimitUsers = ref(2000);
const initialPageUsers = ref(1);
const instagramLiveStatus = ref({} as InstagramLiveStatus);

/*
  Computed
*/
const pluginId = computed(() => router.currentRoute.value.params.pluginId);
const pluginType = computed(() => router.currentRoute.value.params.pluginType);
const accountId = computed(() => router.currentRoute.value.params.accountId);
const mediaId = computed(() => router.currentRoute.value.params.mediaId);

/*
  Methods
*/
const loadAccountLiveStatus = async () => {
  try {
    const res = await pluginAccountLiveStatus({
      pluginId: `${pluginId.value}`,
      pluginType: `${pluginType.value}`,
    });

    if (!res || !Array.isArray(res.data) || res.data.length === 0) {
      instagramLiveStatus.value = {} as InstagramLiveStatus;
      return;
    }

    console.log('res.data', res.data);

    const { data } = res;
    [instagramLiveStatus.value] = data;
  } catch (err) {
    console.error('Error loading account live status:', err);
    instagramLiveStatus.value = {} as InstagramLiveStatus;
  }
};

/*
  Hooks
*/
onBeforeMount(async () => {
  await loadAccountLiveStatus();
});
</script>

<style scoped lang="scss">
</style>

<template>
  <nav
    ref="topMenu"
    class="navbar is-fixed-top is-white has-background-white"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <RouterLink
        :to="{ name: 'HomePage' }"
        class="navbar-item"
        aria-label="navbar main logo"
        @click="goHome"
      >
        <SmallLogoComponent />
      </RouterLink>

      <a
        href="#top"
        class="navbar-burger"
        :class="{ 'is-active': isActive }"
        aria-label="Toggle Menu"
        @click="toggleMenu"
        @keyup.enter="toggleMenu"
      >
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </a>
    </div>
    <div
      :class="['navbar-menu', { 'is-active': isActive }]"
    >
      <!-- <div class="navbar-start">
        <RouterLink
          class="navbar-item"
          to="#top"
          @click="closeMenu"
        >
          Home
        </RouterLink>
        <RouterLink
          class="navbar-item"
          to="#channel"
          @click="closeMenu"
        >
          Novo canal
        </RouterLink>
        <RouterLink
          class="navbar-item"
          to="#about-us"
          @click="closeMenu"
        >
          Sobre
        </RouterLink>
        <RouterLink
          class="navbar-item"
          to="#contact-us"
          @click="closeMenu"
        >
          Contato
        </RouterLink>
      </div> -->
      <!-- <div :class="['navbar-end', { 'is-hidden': !isAuthenticated() }]">
        <div class="navbar-item">
          <button
            class="button is-primary"
            type="button"
            @click="switchAccount"
          >
            Trocar de conta
          </button>
        </div>
        <div class="navbar-item">
          <button
            class="button is-clear"
            type="button"
            :disabled="submitDisabled"
            @click="logout"
          >
            Logout
          </button>
        </div>
      </div>
      <hr> -->
      <div :class="['navbar-end', { 'is-hidden': isAuthenticated() }]">
        <div class="navbar-item">
          <div class="buttons">
            <RouterLink
              class="button is-primary"
              :to="{
                name: 'SessionSignupPage'
              }"
            >
              Criar minha conta
            </RouterLink>
            <RouterLink
              class="button is-light"
              :to="{
                name: 'SessionLoginPage'
              }"
            >
              Login
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <LoadingComponent v-if="isLoading" />
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import AuthStore from '@/store/auth';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';
import SmallLogoComponent from '@/components/shared/SmallLogoComponent.vue';

const router = useRouter();
const { isAuthenticated } = AuthStore();

/*
  Data
*/
const isActive = ref(false);
const isLoading = ref(false);
const topMenu = ref('');

/*
  Methods
*/
const goHome = () => {
  router.push({
    name: 'HomePage',
  });
  // window.open('/', '_self');
};
// const closeMenu = () => {
//   isActive.value = false;
// };
const toggleMenu = () => {
  isActive.value = !isActive.value;
};

/*
  Hooks
*/
onMounted(() => {
  // topMenu.value.addEventListener('click', closeMenu);
});

</script>

<style scoped lang='scss'>
  .navbar {
    min-height: 3.5rem;
    height: 3.5rem;
  }
  .navbar-brand {
    min-height: 3.5rem;
  }
</style>

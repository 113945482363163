<template>
  <AppHeader />
  <main class="main has-navbar-fixed-top">
    <section class="section">
      <div class="slideDown container has-text-centered-mobile">
        <div class="columns is-vcentered">
          <div class="column">
            <p class="title">
              Boas-vindas à <span style="white-space:nowrap">Live Link</span>
            </p>
            <div
              v-if="isStoreModeMerchant()"
            >
              <p class="subtitle is-size-6 m-0 is-hidden-mobile">
                <!-- eslint-disable-next-line max-len -->
                Explore oportunidades emocionantes e gerencie seu negócio com facilidade.
              </p>
              <p class="subtitle is-size-6 m-0">
                <!-- eslint-disable-next-line max-len -->
                Crie Lives, destaque seus produtos e mantenha contato com seu público de maneira eficaz.
              </p>
            </div>
            <div
              v-else
              class="subtitle"
            >
              <p class="subtitle is-size-6 m-0">
                <!-- eslint-disable-next-line max-len -->
                Explore oportunidades emocionantes e fique conectado com seus clientes.
              </p>
              <!-- <p class="subtitle is-size-6 m-0">
                Ou então, crie sua loja e começe a vender!
              </p> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <RouterView />
    <notifications classes="toast-notification" />
  </main>
</template>

<script setup lang="ts">
import {
  onMounted,
} from 'vue';
import { useRouter } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';
import useAuthStore from '@/store/auth';
import AppHeader from '@/components/header/AppHeader.vue';

const router = useRouter();
const {
  isStoreModeMerchant,
  // toggleStoreMode,
} = useAuthStore();

/*
  Hooks
*/
onMounted(() => {
  if (router.currentRoute.value?.query?.message) {
    notify({
      duration: 5000,
      type: router.currentRoute.value?.query?.type || 'Error',
      title: 'Ooops',
      text: router.currentRoute.value?.query?.message,
    });
  }
});
</script>

<style lang="scss" scoped>
</style>

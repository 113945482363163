import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withKeys as _withKeys, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/explore.svg'


const _hoisted_1 = { class: "hero is-fullheight" }
const _hoisted_2 = { class: "columns m-0 gradient-container" }
const _hoisted_3 = { class: "column is-flex is-align-items-center pl-0 pr-0" }
const _hoisted_4 = { class: "column is-8 is-offset-2 pt-0" }
const _hoisted_5 = { class: "box" }
const _hoisted_6 = { class: "block is-flex" }
const _hoisted_7 = { class: "field has-text-right mt-6" }
const _hoisted_8 = { class: "control is-flex is-justify-content-flex-end" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = { class: "has-text-centered" }

import {
  ref,
  computed,
  defineAsyncComponent,
} from 'vue';
import { useRouter } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';

import AuthStore from '@/store/auth';
import useUserCookies from '@/use/useUserCookies';
import TextFieldInput from '@/components/form/TextFieldInput.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SignupView',
  setup(__props) {

const EmailInput = defineAsyncComponent(() => import('@/components/form/EmailInput.vue'));
const TermsAndConditionsInput = defineAsyncComponent(() => import('@/components/form/TermsAndConditionsInput.vue'));
const CPFInput = defineAsyncComponent(() => import('@/components/form/CPFInput.vue'));
const SmallLogoComponent = defineAsyncComponent(() => import('@/components/shared/SmallLogoComponent.vue'));

const router = useRouter();
const { authDoSignup } = AuthStore();

/*
  Data
*/
const documentType = ref('CPF');
const name = ref('');
const nameError = ref('');
const document = ref('');
const documentError = ref('');
const email = ref('');
const emailError = ref('');
const acceptTerms = ref(false);
const acceptTermsError = ref('');
const { getLanguage, getTimezone } = useUserCookies();
const isLoading = ref(false);

/*
  Computed
*/
const getTermsAndConditionsLink = computed(() => ({
  text: 'Li e aceito os',
  linkText: 'Termos e Condições',
  link: '/terms-and-conditions',
  target: '_blank',
}));
const submitEnabled = computed(() => (
  !!document.value
  && !documentError.value
  && !!name.value
  && !nameError.value
  && !!email.value
  && !emailError.value
  && !!acceptTerms.value
  && !acceptTermsError.value
));

/*
  Methods
*/
const submitForm = async () => {
  isLoading.value = true;
  authDoSignup(
    await getLanguage(),
    await getTimezone(),
    name.value,
    document.value,
    documentType.value,
    email.value,
  ).then((response) => {
    if (response?.status !== 200) {
      throw new Error('Não foi possível completar sua solicitação. Tente novamente mais tarde.');
    }
    router.push({ name: 'SessionTemporaryPasswordPage' });
  }).catch((error) => {
    let { message } = error;
    if (message === undefined) {
      message = error;
    }
    if (error === undefined) {
      message = 'Não foi possível completar sua solicitação. Tente novamente mais tarde. (x002)';
    }

    notify({
      duration: 5000,
      type: 'error',
      title: 'Ooops',
      text: message,
    });
    isLoading.value = false;
  });
};

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_notifications = _resolveComponent("notifications")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_RouterLink, { to: { name: 'HomePage' } }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(SmallLogoComponent))
                  ]),
                  _: 1
                })
              ]),
              _cache[13] || (_cache[13] = _createElementVNode("div", { class: "block" }, [
                _createElementVNode("p", { class: "title has-text-primary" }, " Criar uma Conta "),
                _createElementVNode("p", null, " Utilize seu melhor email, pois enviaremos sua senha de primeiro acesso para ele. ")
              ], -1)),
              _cache[14] || (_cache[14] = _createElementVNode("hr", null, null, -1)),
              _createElementVNode("form", {
                class: "mt-5",
                onKeydown: _cache[9] || (_cache[9] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"])),
                onSubmit: _withModifiers(submitForm, ["prevent"])
              }, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((documentType).value = $event)),
                  type: "hidden",
                  class: "input",
                  autocomplete: "document-type",
                  readonly: ""
                }, null, 512), [
                  [_vModelText, documentType.value]
                ]),
                (_openBlock(), _createBlock(_resolveDynamicComponent(TextFieldInput), {
                  modelValue: name.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((name).value = $event)),
                  "input-id": "name",
                  "is-loading": isLoading.value,
                  "input-required": true,
                  "input-label": "Nome Completo",
                  "input-placeholder": "Como consta no seu documento",
                  "input-min-length": 6,
                  "input-min-words": 2,
                  "input-max-length": 256,
                  "input-min-words-error": "Preencha seu nome completo",
                  "input-auto-complete": "name",
                  "input-error": nameError.value,
                  "onUpdate:inputError": _cache[2] || (_cache[2] = (value) => nameError.value = value)
                }, null, 40, ["modelValue", "is-loading", "input-error"])),
                (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(CPFInput)), {
                  modelValue: document.value,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((document).value = $event)),
                  "input-id": "documentId",
                  "is-loading": isLoading.value,
                  "input-required": true,
                  "input-label": "CPF",
                  "input-placeholder": "Utilize apenas números",
                  "input-error": documentError.value,
                  "onUpdate:error": _cache[4] || (_cache[4] = (value) => documentError.value = value)
                }, null, 40, ["modelValue", "is-loading", "input-error"])),
                (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(EmailInput)), {
                  modelValue: email.value,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((email).value = $event)),
                  "input-id": "emailId",
                  "is-loading": isLoading.value,
                  "input-required": true,
                  "input-label": "Email",
                  "input-placeholder": "Iremos validar seu email",
                  "input-error": emailError.value,
                  "onUpdate:inputError": _cache[6] || (_cache[6] = (value) => emailError.value = value)
                }, null, 40, ["modelValue", "is-loading", "input-error"])),
                _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
                (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(TermsAndConditionsInput)), {
                  modelValue: acceptTerms.value,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((acceptTerms).value = $event)),
                  "input-error": acceptTermsError.value,
                  "input-label": "Termos e Condições",
                  "input-id": "terms",
                  "is-loading": isLoading.value,
                  "input-placeholder": getTermsAndConditionsLink.value,
                  "onUpdate:inputError": _cache[8] || (_cache[8] = (value) => acceptTermsError.value = value)
                }, null, 40, ["modelValue", "input-error", "is-loading", "input-placeholder"])),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("button", {
                      class: _normalizeClass(['box', 'button', 'is-primary', {
                      'is-loading': isLoading.value,
                    }]),
                      type: "submit",
                      disabled: !submitEnabled.value || isLoading.value
                    }, " Criar minha conta ", 10, _hoisted_9)
                  ])
                ])
              ], 32),
              _cache[15] || (_cache[15] = _createElementVNode("hr", null, null, -1)),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("p", null, [
                  _cache[12] || (_cache[12] = _createTextVNode(" Já tem uma conta? Faça ")),
                  _createVNode(_component_RouterLink, {
                    to: { name: 'SessionLoginPage' },
                    class: "has-text-primary"
                  }, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createTextVNode(" Login ")
                    ])),
                    _: 1
                  })
                ])
              ])
            ])
          ])
        ]),
        _cache[16] || (_cache[16] = _createStaticVNode("<div class=\"column is-flex is-align-items-center is-justify-content-center\" style=\"height:100vh;\" data-v-60e7ae68><div class=\"columns is-multiline\" data-v-60e7ae68><div class=\"column is-full\" data-v-60e7ae68><p class=\"title\" data-v-60e7ae68> Maximize o impacto do video e multiplique sua taxa de conversão </p></div><div class=\"column is-full\" data-v-60e7ae68><p class=\"subtitle\" data-v-60e7ae68> Atinja conversões 10x superiores ou mais que seu e-commerce: Descubra o poder da transmissão ao vivo com a Live Link </p></div><div class=\"column is-full\" data-v-60e7ae68><figure class=\"image is-4by3\" data-v-60e7ae68><img alt=\"Placeholder image\" src=\"" + _imports_0 + "\" data-v-60e7ae68></figure></div></div></div>", 1))
      ])
    ]),
    _createVNode(_component_notifications, { classes: "toast-notification" })
  ], 64))
}
}

})
<template>
  <RouterView
    v-if="dataLoaded"
    :key="routeKey"
    :merchant-uuid="merchantUuid"
    :brand-id="productGateway"
    :products="products"
    :pagination="pagination"
    :is-loading="isLoading"
    @load:products="(value) => reloadProducts(value)"
    @delete:product="(value) => deleteProduct(value)"
  />
  <LoadingComponent
    v-if="isLoading"
    :full-screen="false"
  />
  <notifications classes="toast-notification" />
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  onBeforeMount,
} from 'vue';
import { useRouter } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';
import useProductStore from '@/store/yampi/product';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';

const router = useRouter();

const {
  productsDoSearch,
  productsDoDelete,
} = useProductStore();

/*
  Data
*/
const limit = ref(
  router.currentRoute.value.query.limit
    ? parseInt(router.currentRoute.value.query.limit, 10)
    : 10,
);
const page = ref(
  router.currentRoute.value.query.page
    ? parseInt(router.currentRoute.value.query.page, 10)
    : 1,
);
const products = ref([]);
const pagination = ref({});
const isLoading = ref(true);
const dataLoaded = ref(false);

/*
  Computed
*/
const routeKey = computed(() => router.fullPath);
const productGateway = computed(() => router.currentRoute.value.params.brandId);
const merchantUuid = computed(() => router.currentRoute.value.params.uuid);

/*
  Methods
*/
const deleteProduct = async (value) => {
  const { name, id } = value;
  // eslint-disable-next-line no-alert
  const confirmed = window.confirm(`Deseja remover o Produto:\n${name}?`);
  if (confirmed) {
    isLoading.value = true;
    await productsDoDelete(id).then((success) => {
      if (success === false) {
        throw new Error('Não foi possível completar sua solicitação. Tente novamente mais tarde.');
      }
      notify({
        duration: 5000,
        type: 'success',
        title: 'Uhuuu',
        text: 'Removido com sucesso',
      });
      products.value = products.value.filter((product) => product.id !== id);
    }).catch((error) => {
      let { message } = error;
      if (message === undefined) {
        message = error;
      }
      if (error === undefined) {
        message = 'Não foi possível completar sua solicitação. Tente novamente mais tarde. (x002)';
      }
      notify({
        duration: 5000,
        type: 'error',
        title: 'Ooops',
        text: message,
      });
    }).finally(() => {
      isLoading.value = false;
    });
  }
};
const load = async (page_: number, limit_:number) => {
  await productsDoSearch({
    merchantUuid: merchantUuid.value,
    productGateway: productGateway.value,
    limit: limit_,
    page: page_,
  }, true).then((response) => {
    if (response !== undefined) {
      products.value = response.data;
      pagination.value = response.pagination;
    }
    isLoading.value = false;
  }).catch((error) => {
    console.log('error', error);
    notify({
      duration: 5000,
      type: 'error',
      title: 'Ooops',
      text: error.toString(),
    });
  });
};
const reloadProducts = async (value) => {
  const { page: page_, limit: limit_ } = value;
  page.value = page_;
  limit.value = limit_;
  isLoading.value = true;
  await load(page_, limit_);
};

/*
  Hooks
*/
onBeforeMount(async () => {
  await load(page.value, limit.value);
  dataLoaded.value = true;
});
</script>

<style lang="scss" scoped>
</style>

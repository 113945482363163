<template>
  <section class="hero is-fullheight">
    <div class="columns m-0 gradient-container">
      <div class="column is-flex is-align-items-center pl-0 pr-0">
        <div class="column is-8 is-offset-2 pt-0">
          <div class="box">
            <div class="block is-flex">
              <RouterLink :to="{ name: 'HomePage' }">
                <SmallLogoComponent />
              </RouterLink>
            </div>
            <div class="block">
              <p class="title has-text-primary">
                Fazer login
              </p>
              <p>Utilize seu CPF e senha para acessar sua conta.</p>
            </div>
            <div
              v-if="errorMessage"
              class="error-message has-text-centered p-3"
            >
              {{ errorMessage }}
            </div>
            <hr>
            <form
              class="mt-5"
              @keydown.enter.prevent
              @submit.prevent="submitForm"
            >
              <input
                v-model="documentType"
                type="hidden"
                class="input"
                autocomplete="document-type"
                readonly
              >
              <component
                :is="CPFInput"
                v-model="document"
                input-id="documentId"
                :is-loading="isLoading"
                :input-required="true"
                input-label="CPF"
                input-placeholder="Utilize apenas números"
                :input-error="documentError"
                @update:error="(value) => { documentError = value; }"
              />
              <component
                :is="PasswordInput"
                v-model="password"
                input-id="passwordId"
                :is-loading="isLoading"
                :input-required="true"
                input-label="Senha"
                input-placeholder="Informe sua senha"
                :input-error="passwordError"
                :show-password="showPassword"
                @update:error="(value) => { passwordError = value; }"
                @update:show-password="(value) => showPassword = value"
              />
              <div class="field has-text-left">
                <div class="control">
                  <RouterLink
                    :to="{ name: 'SessionRecoverPage' }"
                    class="has-text-primary"
                  >
                    Recuperar minha senha
                  </RouterLink>
                </div>
              </div>
              <div class="field mt-6">
                <div class="control is-flex is-justify-content-flex-end">
                  <button
                    :class="['box', 'button', 'is-primary', {
                      'is-loading': isLoading,
                    }]"
                    type="submit"
                    :disabled="!submitEnabled || isLoading"
                  >
                    Fazer login
                  </button>
                </div>
              </div>
            </form>
            <hr>
            <div class="has-text-centered">
              <p>
                Ainda não tem uma conta?
                <RouterLink
                  :to="{ name: 'SessionSignupPage' }"
                  class="has-text-primary"
                >
                  <span class="whitespace: nowrap">Cadastre-se</span>
                </RouterLink>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          column
          is-flex
          is-align-items-center
          is-justify-content-center"
        style="height: 100vh"
      >
        <div class="columns is-multiline">
          <div class="column is-full">
            <p class="title">
              Maximize o impacto do video e multiplique sua taxa de conversão
            </p>
          </div>
          <div class="column is-full">
            <p class="subtitle">
              <!-- eslint-disable-next-line max-len -->
              Atinja conversões 10x superiores ou mais que seu e-commerce: Descubra o poder da transmissão ao vivo com a Live Link
            </p>
          </div>
          <div class="column is-full">
            <figure class="image is-4by3">
              <img
                alt="Placeholder image"
                src="@/assets/images/mobile_login.svg"
              >
            </figure>
          </div>
        </div>
      </div>
    </div>
  </section>
  <notifications classes="toast-notification" />
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  defineAsyncComponent,
} from 'vue';
import { useRouter } from 'vue-router';
import useAuthStore from '@/store/auth';
import { notify } from '@kyvg/vue3-notification';

const CPFInput = defineAsyncComponent(() => import('@/components/form/CPFInput.vue'));
const PasswordInput = defineAsyncComponent(() => import('@/components/form/PasswordInput.vue'));
const SmallLogoComponent = defineAsyncComponent(() => import('@/components/shared/SmallLogoComponent.vue'));

const router = useRouter();
const { authDoLogin } = useAuthStore();

/*
  Data
*/
const documentType = ref('CPF');
const document = ref('');
const documentError = ref('');
const password = ref('');
const passwordError = ref('');
const showPassword = ref(false);
const isLoading = ref(false);
const nextUrl = router.currentRoute.value?.query?.redirect;

/*
  Computed
*/
const errorMessage = computed(() => router.currentRoute.value?.query?.error);
const submitEnabled = computed(() => (
  !!document.value && !documentError.value
  && !!password.value && !passwordError.value
));

/*
  Methods
*/
const submitForm = async () => {
  isLoading.value = true;
  await authDoLogin(
    document.value,
    documentType.value,
    password.value,
  ).then(() => {
    if (
      nextUrl !== ''
      && nextUrl !== undefined
      && nextUrl !== '/session/refresh'
    ) {
      router.push(nextUrl);
    } else {
      router.push({ name: 'DashboardPage' });
    }
  }).catch((error) => {
    let { message } = error;
    if (message === undefined) {
      message = error;
    }
    if (error === undefined) {
      message = 'Não foi possível completar sua solicitação. Tente novamente mais tarde. (x002)';
    }

    notify({
      duration: 5000,
      type: 'error',
      title: 'Ooops',
      text: message,
    });
    isLoading.value = false;
  });
};
</script>

<style scoped lang="scss">
.error-message {
  color: red;
}
.gradient-container {
  width: 100%;
  background: linear-gradient(0.35turn, #00d1b2 5%, #f0f4f7 70%);
}
</style>

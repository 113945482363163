class InstagramLiveMessageReply {
  text: string;

  timestamp: Date;

  constructor({
    text,
    timestamp,
  }: {
    text: string;
    timestamp: Date;
  }) {
    this.text = text;
    this.timestamp = timestamp;
  }

  // return only day month year and hour of the createdAt
  formattedTimestamp(): string {
    return this.timestamp.toLocaleDateString('pt-BR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      hour12: true,
    });
  }

  static fromApiObject(replyObj: any): InstagramLiveMessageReply {
    return new InstagramLiveMessageReply({
      text: replyObj.text || '',
      timestamp: replyObj.timestamp ? new Date(replyObj.timestamp) : new Date(0),
    });
  }
}

export default InstagramLiveMessageReply;

import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "main has-navbar-fixed-top" }
const _hoisted_2 = { class: "section" }
const _hoisted_3 = { class: "slideDown container has-text-centered-mobile" }
const _hoisted_4 = { class: "columns is-vcentered" }
const _hoisted_5 = { class: "column" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "subtitle"
}

import {
  onMounted,
} from 'vue';
import { useRouter } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';
import useAuthStore from '@/store/auth';
import AppHeader from '@/components/header/AppHeader.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardLayout',
  setup(__props) {

const router = useRouter();
const {
  isStoreModeMerchant,
  // toggleStoreMode,
} = useAuthStore();

/*
  Hooks
*/
onMounted(() => {
  if (router.currentRoute.value?.query?.message) {
    notify({
      duration: 5000,
      type: router.currentRoute.value?.query?.type || 'Error',
      title: 'Ooops',
      text: router.currentRoute.value?.query?.message,
    });
  }
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_notifications = _resolveComponent("notifications")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(AppHeader),
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("section", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[2] || (_cache[2] = _createElementVNode("p", { class: "title" }, [
                _createTextVNode(" Boas-vindas à "),
                _createElementVNode("span", { style: {"white-space":"nowrap"} }, "Live Link")
              ], -1)),
              (_unref(isStoreModeMerchant)())
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[0] || (_cache[0] = [
                    _createElementVNode("p", { class: "subtitle is-size-6 m-0 is-hidden-mobile" }, " Explore oportunidades emocionantes e gerencie seu negócio com facilidade. ", -1),
                    _createElementVNode("p", { class: "subtitle is-size-6 m-0" }, " Crie Lives, destaque seus produtos e mantenha contato com seu público de maneira eficaz. ", -1)
                  ])))
                : (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[1] || (_cache[1] = [
                    _createElementVNode("p", { class: "subtitle is-size-6 m-0" }, " Explore oportunidades emocionantes e fique conectado com seus clientes. ", -1)
                  ])))
            ])
          ])
        ])
      ]),
      _createVNode(_component_RouterView),
      _createVNode(_component_notifications, { classes: "toast-notification" })
    ])
  ], 64))
}
}

})
<template>
  <div class="field">
    <div class="control">
      <label
        :for="props.inputId"
        :class="['label', {
          'has-text-grey-light': props.isLoading || props.isDisabled,
        }]"
      >
        {{ props.inputLabel }}
        <span
          v-if="props.inputRequired"
          class="tag"
        >
          obrigatório
        </span>
        <p
          v-if="props.inputSubLabel.length > 0"
          class="help"
        >
          {{ props.inputSubLabel }}
        </p>
        <input
          :id="props.inputId"
          :value="props.modelValue"
          :disabled="props.isLoading || props.isDisabled"
          :class="[
            'input',
            'is-fullwidth',
            {
              'is-danger': props.inputError,
            },
            {
              'is-success': props.modelValue && !props.inputError,
            },
          ]"
          type="email"
          autocomplete="email"
          :placeholder="props.inputPlaceholder"
          @input="handleInput"
          @keydown.space.prevent
          @keyup.space.prevent
          @blur="validateInput"
        >
      </label>
      <p
        v-if="props.inputError"
        class="help is-danger"
      >
        {{ props.inputError }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps, defineEmits } from 'vue';

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
    default: '',
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
  isDisabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  inputId: {
    type: String,
    required: true,
  },
  inputSubLabel: {
    type: String,
    required: false,
    default: '',
  },
  inputRequired: {
    type: Boolean,
    required: true,
  },
  inputLabel: {
    type: String,
    required: true,
  },
  inputPlaceholder: {
    default: '',
    type: String,
    required: false,
  },
  inputError: {
    type: String,
    required: true,
  },
});

const emit = defineEmits([
  'update:modelValue',
  'update:inputError',
]);

const inputTrimmed = computed(() => props.modelValue.trim());

const isValidEmail = ((email: string) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email));

const handleInput = (event: InputEvent) => {
  const localModelValue = (event.target as HTMLInputElement).value;
  emit('update:modelValue', localModelValue);
};

const validateInput = () => {
  if (inputTrimmed.value.length === 0) {
    emit('update:inputError', '');
  } else if (!inputTrimmed.value) {
    emit('update:inputError', 'Campo obrigatório');
  } else if (!isValidEmail(inputTrimmed.value)) {
    emit('update:inputError', 'Email inválido');
  } else {
    emit('update:inputError', '');
  }
};
</script>

<style scoped lang="scss">
/* component styles */
</style>

<template>
  <div class="has-text-left is-white mb-0">
    <button
      type="button"
      class="button is-black is-inverted"
      @click="props.params ?
        router.push({ name: props.to, params: props.params, query: props.query })
        : router.push({ name: props.to })"
    >
      <span class="icon-text">
        <span class="fa-lg">
          <font-awesome-icon
            :icon="props.icon"
            class="fa-lg"
          />
        </span>
        <span v-if="props.text">{{ props.text }}</span>
      </span>
    </button>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps({
  to: {
    type: String,
    required: true,
  },
  params: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  query: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  text: {
    type: String,
    required: false,
    default: '',
  },
  icon: {
    type: String,
    required: true,
  },
});
</script>

<style lang="scss" scoped>
</style>

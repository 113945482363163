import { RouteRecordRaw } from 'vue-router';
import PluginsLayout from '@/components/layout/plugins/ManagerLayout.vue';
import PluginReportLayout from '@/components/layout/plugins/PluginReportLayout.vue';
import PluginLivesLayout from '@/components/layout/plugins/PluginLivesLayout.vue';
import PluginLiveReportLayout from '@/components/layout/plugins/PluginLiveReportLayout.vue';
import NoHeaderLayout from '@/components/layout/NoHeaderLayout.vue';

const pluginsRoutes: Array<RouteRecordRaw> = [
  {
    path: '/plugins',
    name: 'PluginsManagerPage',
    meta: {
      label: 'PluginsPage',
      layout: PluginsLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "plugins" */ '@/views/plugins/MainView.vue'),
  },
  {
    path: '/plugins/callback/shopify',
    name: 'PluginsCallbackShopifyPage',
    meta: {
      label: 'PluginsPage',
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "plugins" */ '@/views/plugins/ShopifyCallback.vue'),
  },
  {
    path: '/plugins/callback/instagram',
    name: 'PluginsCallbackInstagramPage',
    meta: {
      label: 'PluginsPage',
      layout: NoHeaderLayout,
      accessWithSession: undefined,
    },
    component: () => import(/* webpackChunkName: "plugins" */ '@/views/plugins/InstagramCallback.vue'),
  },
  {
    path: '/plugins/:account/reports',
    name: 'PluginsReportPage',
    meta: {
      label: 'ReportsPage',
      layout: PluginReportLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "plugins" */ '@/views/plugins/PluginReportPage.vue'),
  },
  {
    path: '/plugins/v2/:pluginId/:pluginType/lives',
    name: 'PluginLivesPage',
    meta: {
      label: 'ReportsPage',
      layout: PluginLivesLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "plugins" */ '@/views/plugins/PluginLivesView.vue'),
  },
  {
    path: '/plugins/v2/:pluginId/:pluginType/:accountId/medias/:mediaId/report',
    name: 'PluginLiveReportPage',
    meta: {
      label: 'ReportsPage',
      layout: PluginLiveReportLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "plugins" */ '@/views/plugins/PluginLiveReportView.vue'),
  },
];

export default pluginsRoutes;

<template>
  <PageHeader
    :is-loading="false"
    title="Gravações de transmissões"
    to="StreamManagerPage"
    :params="{ uuid: stream.uuid }"
  />
  <main class="main has-navbar-fixed-top">
    <section class="section">
      <div class="section-content content">
        <div
          :class="[{
            'is-ellipsis clamp-2-lines': !expanded,
          }]"
        >
          <span class="subtitle is-size-6">
            Na página, você encontrará o vídeo de todas as suas transmissões gravadas.
            Mesmo se pausar e retomar a live, cada momento estará disponível.
            Para poder utilizar em outras plataformas, basta solicitar o download
            abaixo do vídeo desejado. Um aviso surgirá explicando como receber o
            link por e-mail (verifique também o spam).
            Lembre-se, arquivos maiores demandam mais tempo para ficarem prontos.
          </span>
        </div>
        <a
          href="#"
          class="has-text-link no-underline"
          @click.prevent="expanded = !expanded"
        >
          {{ expanded ? 'ver menos' : 'continuar lendo' }}
        </a>
      </div>
    </section>
    <RouterView
      v-if="isDataLoaded"
      :stream="stream"
    />
  </main>
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  onBeforeMount,
  onUnmounted,
} from 'vue';
import { useRouter } from 'vue-router';
import useStreamStore from '@/store/stream';
import PageHeader from '@/components/header/PageHeader.vue';

const router = useRouter();
const { streamDoGet, resetStore } = useStreamStore();

/*
  Computed
*/
const streamUri = computed(() => router.currentRoute.value.params.uri);

/*
  Data
*/
const isDataLoaded = ref(false);
const expanded = ref(false);
const stream = ref({});

/*
  Methods
*/
const load = async () => {
  await streamDoGet(streamUri.value).then((result) => {
    if (result === undefined) {
      router.push({ name: 'NotFoundPage' });
    } else {
      stream.value = result;
    }
    if (!stream.value.landingPageActive) {
      router.push({ name: 'HomePage' });
    }
    isDataLoaded.value = true;
  }).catch((error) => {
    console.log(error);
  });
};

/*
  Hooks
*/
onBeforeMount(() => {
  load();
});
onUnmounted(() => {
  resetStore();
});
</script>

<style lang="scss" scoped>
</style>

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "column is-full p-0" }

import {
  ref,
  computed,
  onBeforeMount,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';
import useStreamStore from '@/store/stream';
import useAuthStore from '@/store/auth';
import Stream from '@/models/stream';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BroadcastModerateLayout',
  setup(__props) {

const router = useRouter();
const {
  session,
} = useAuthStore();
const {
  getMyStream,
  streamsDoGetMy,
  streamStore,
  streamsDoGetRealtimeTokens,
} = useStreamStore();

/*
  Data
*/
const userRole = ref(2); // 1 = host, 2 = audience
const isLoading = ref(true);
const myStream = ref({});
const rtt = ref(null);

/*
  Computed
*/
const streamUuid = computed(() => router.currentRoute.value.params.uuid);

/*
  Methods
*/
const redirectDashboard = ((error: string) => router.push({
  name: 'DashboardPage',
  query: {
    message: 'Não foi possível carregar sua live. Tente novamente em instantes.',
    detail: error,
    type: 'error',
  },
}));
const reloadStream = async () => {
  await streamsDoGetMy({ uuid: streamUuid.value }).then(async (response) => {
    if (response === undefined) {
      setTimeout(() => redirectDashboard('Não foi possível completar sua solicitação. Verifique o link e tente novamente'), 300);
      return;
    }
    myStream.value = await getMyStream();
  }).catch((error) => {
    console.log('error', error);
    redirectDashboard(error.toString());
  }).finally(() => {
    isLoading.value = false;
  });
};
const load = async (stream: Stream) => {
  isLoading.value = true;
  if (stream == null) {
    rtt.value = await streamsDoGetRealtimeTokens(
      streamUuid.value,
      userRole.value,
    );
    reloadStream();
  } else {
    myStream.value = stream;
    isLoading.value = false;
  }
};

/*
  Hooks
*/
onBeforeMount(async () => {
  load();
});
watch(streamStore, () => {
  myStream.value = getMyStream();
}, { deep: true });

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (myStream.value && rtt.value)
          ? (_openBlock(), _createBlock(_component_RouterView, {
              key: 0,
              stream: myStream.value,
              rtt: rtt.value,
              "user-name": _unref(session).name,
              "onUpdate:myStream": load,
              "onReload:stream": reloadStream
            }, null, 8, ["stream", "rtt", "user-name"]))
          : _createCommentVNode("", true)
      ])
    ]),
    (isLoading.value)
      ? (_openBlock(), _createBlock(LoadingComponent, {
          key: 0,
          "full-screen": true
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})
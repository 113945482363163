import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/logo.png'


const _hoisted_1 = {
  key: 0,
  class: "tag is-danger is-rounded",
  style: {"margin-left":"-0.3rem"}
}
const _hoisted_2 = { class: "wrapper-content" }
const _hoisted_3 = {
  style: {},
  class: "section shopping-cart p-4"
}
const _hoisted_4 = { class: "columns is-multiline" }
const _hoisted_5 = { class: "column is-full is-flex is-justify-content-flex-end" }
const _hoisted_6 = { class: "fa-2x" }
const _hoisted_7 = { class: "column is-full" }
const _hoisted_8 = {
  key: 0,
  class: ""
}
const _hoisted_9 = {
  key: 1,
  class: "p-0"
}
const _hoisted_10 = { class: "column is-4-mobile is-3 p-1 m-0 is-vcentered" }
const _hoisted_11 = {
  key: 0,
  class: "m-auto p-0 has-text-centered"
}
const _hoisted_12 = ["src", "alt"]
const _hoisted_13 = {
  key: 1,
  class: "m-auto p-0 has-text-centered"
}
const _hoisted_14 = { class: "column p-1 m-0" }
const _hoisted_15 = { class: "columns is-multiline is-vcentered" }
const _hoisted_16 = { class: "column is-full pb-0" }
const _hoisted_17 = { class: "title is-size-6 is-size-7-mobile m-0" }
const _hoisted_18 = { class: "is-lowercase is-size-7" }
const _hoisted_19 = { class: "subtitle is-size-7" }
const _hoisted_20 = { class: "column is-narrow" }
const _hoisted_21 = { class: "subtitle is-size-6 is-flex is-align-items-center" }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = { class: "m-2" }
const _hoisted_24 = ["onClick"]
const _hoisted_25 = {
  key: 0,
  class: "subtitle is-6 ml-4 has-text-grey"
}
const _hoisted_26 = { class: "column is-full" }
const _hoisted_27 = { class: "columns is-mobile is-vcentered is-multiline" }
const _hoisted_28 = { class: "column has-text-right" }
const _hoisted_29 = { class: "column is-full" }

import {
  ref,
  onBeforeMount,
  // watch,
} from 'vue';
import useShoppingCartStore from '@/store/yampi/shoppingCart';
import MixpanelStore from '@/store/mixpanel/broadcastLive';
import Product from '@/models/yampi/product/product';
import ShoppingCart from '@/models/yampi/shoppingCart/shoppingCart';


export default /*@__PURE__*/_defineComponent({
  __name: 'YampiShoppingCartComponent',
  props: {
  buttonColor: {
    type: String,
    reqiore: false,
    default: '#FFF',
  },
  streamUuid: {
    type: String,
    required: true,
  },
  merchantUuid: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const mixpanel = new MixpanelStore();
const {
  // shoppingCartStore,
  getShoppingCartTotal,
  getShoppingCart,
  formatPrice,
} = useShoppingCartStore();

const props = __props;

/*
  Data
*/
const streamUuid = ref('');
const merchantUuid = ref('');
const shoppingCart = ref({} as ShoppingCart);
const showShoppingCart = ref(false);
const productQuantity = ref([] as Product[]);

/*
  Methods
*/
const increaseShoppingCartItemQuantity = async (
  item: Product,
  quantity: number,
) => {
  shoppingCart.value.increaseQuantity(item.productSku.token, quantity);
  window.xxx = item;
  console.log(item);
  mixpanel.shoppingCartIncreaseQtyClick(
    props.merchantUuid,
    props.streamUuid,

    false, // isAdmin
    item.productSku.getTitle(),
    item.productSku.getFormattedPrice(),
    null, // image
    'livelink-yampi',
  );
};
const decreaseShoppingCartItemQuantity = async (
  item: Product,
  quantity: number,
) => {
  shoppingCart.value.decreaseQuantity(item.productSku.token, quantity);
  mixpanel.shoppingCartReduceQtyClick(
    props.merchantUuid,
    props.streamUuid,

    false, // isAdmin
    item.productSku.getTitle(),
    item.productSku.getFormattedPrice(),
    null, // image
    'livelink-yampi',
  );
};
const startCheckout = async () => {
  const url = shoppingCart.value.getCheckoutUrl(streamUuid.value, merchantUuid.value, 'shoppingCart');

  await mixpanel.shoppingCartCheckoutClick(
    props.merchantUuid,
    props.streamUuid,

    false, // isAdmin
    shoppingCart.value.getProductNames(),
    shoppingCart.value.getTotalPrice(),
  );

  window.open(url, '_blank');
};
const toggleShoppingCart = async () => {
  shoppingCart.value = await getShoppingCart(props.streamUuid);
  if (shoppingCart.value !== undefined) {
    productQuantity.value = shoppingCart.value.productQuantity;
  }
  showShoppingCart.value = !showShoppingCart.value;
};
onBeforeMount(() => {
  streamUuid.value = props.streamUuid;
  merchantUuid.value = props.merchantUuid;
});
// watch(shoppingCartStore, async () => {
//   if (!showShoppingCart.value) {
//     toggleShoppingCart();
//   }
// });

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      type: "button",
      class: "button is-outlined is-white ml-2",
      style: _normalizeStyle(['background-color: transparent; color: #FFF', 'color: ' + props.buttonColor]),
      onClick: _withModifiers(toggleShoppingCart, ["prevent"])
    }, [
      _createVNode(_component_font_awesome_icon, {
        style: {"width":"24px","height":"24px"},
        icon: { prefix: 'fa', iconName: 'shopping-cart' }
      }),
      (_unref(getShoppingCartTotal)(props.streamUuid) > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_unref(getShoppingCartTotal)(props.streamUuid)), 1))
        : _createCommentVNode("", true),
      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "ml-2 is-hidden-mobile" }, "Carrinho", -1))
    ], 4),
    (showShoppingCart.value)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "#shopping-cart",
          class: "wrapper is-unselectable"
        }, [
          _createElementVNode("div", {
            class: "wrapper-background",
            onClick: _withModifiers(toggleShoppingCart, ["prevent"]),
            onKeyup: _withModifiers(toggleShoppingCart, ["prevent"])
          }, null, 32),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("section", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("button", {
                    class: "button p-4 is-white is-transparent",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (showShoppingCart.value = false))
                  }, [
                    _createElementVNode("span", _hoisted_6, [
                      _createVNode(_component_font_awesome_icon, { icon: { prefix: 'fa', iconName: 'close' } })
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  (_unref(getShoppingCartTotal)(props.streamUuid) == 0)
                    ? (_openBlock(), _createElementBlock("ul", _hoisted_8, _cache[2] || (_cache[2] = [
                        _createElementVNode("li", null, [
                          _createElementVNode("p", { class: "subtitle is-size-6 has-text-centered" }, " sua carrinho está vazio ")
                        ], -1)
                      ])))
                    : (_openBlock(), _createElementBlock("ul", _hoisted_9, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(productQuantity.value, (item, index) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: index,
                            class: "columns is-multiline is-mobile is-vcentered mb-4"
                          }, [
                            _createElementVNode("div", _hoisted_10, [
                              (item.product.getImageUrl())
                                ? (_openBlock(), _createElementBlock("figure", _hoisted_11, [
                                    _createElementVNode("img", {
                                      src: item.product.getImageUrl('/-/scale_crop/300x350/center/'),
                                      alt: item.product.name
                                    }, null, 8, _hoisted_12)
                                  ]))
                                : (_openBlock(), _createElementBlock("figure", _hoisted_13, _cache[3] || (_cache[3] = [
                                    _createElementVNode("img", {
                                      alt: "sem imagem",
                                      src: _imports_0,
                                      style: {"max-height":"88px","max-width":"100%","padding":"8px"}
                                    }, null, -1)
                                  ])))
                            ]),
                            _createElementVNode("div", _hoisted_14, [
                              _createElementVNode("div", _hoisted_15, [
                                _createElementVNode("div", _hoisted_16, [
                                  _createElementVNode("p", _hoisted_17, _toDisplayString(item.productSku.getTitle()), 1),
                                  _createElementVNode("p", _hoisted_18, _toDisplayString(item.productSku.getFormattedVariantions()), 1),
                                  _createElementVNode("span", _hoisted_19, _toDisplayString(_unref(formatPrice)(item.getTotalPrice())), 1)
                                ]),
                                _createElementVNode("div", _hoisted_20, [
                                  _createElementVNode("div", _hoisted_21, [
                                    _createElementVNode("button", {
                                      class: "button is-small",
                                      type: "button",
                                      onClick: _withModifiers(($event: any) => (decreaseShoppingCartItemQuantity(
                            item, undefined)), ["prevent"])
                                    }, [
                                      _createVNode(_component_font_awesome_icon, {
                                        class: "fa-sm",
                                        icon: { prefix: 'fa', iconName: 'minus' }
                                      })
                                    ], 8, _hoisted_22),
                                    _createElementVNode("p", _hoisted_23, _toDisplayString(item.quantity), 1),
                                    _createElementVNode("button", {
                                      type: "button",
                                      class: "button is-small",
                                      onClick: _withModifiers(($event: any) => (increaseShoppingCartItemQuantity(
                            item, undefined)), ["prevent"])
                                    }, [
                                      _createVNode(_component_font_awesome_icon, {
                                        class: "fa-sm",
                                        icon: { prefix: 'fa', iconName: 'plus' }
                                      })
                                    ], 8, _hoisted_24),
                                    (item.quantity >= 10)
                                      ? (_openBlock(), _createElementBlock("p", _hoisted_25, " máximo de 10 unidades "))
                                      : _createCommentVNode("", true)
                                  ])
                                ])
                              ])
                            ])
                          ]))
                        }), 128)),
                        _cache[7] || (_cache[7] = _createElementVNode("hr", { class: "mb-4" }, null, -1)),
                        _createElementVNode("li", _hoisted_26, [
                          _createElementVNode("div", _hoisted_27, [
                            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "column is-narrow" }, [
                              _createElementVNode("p", { class: "title is-5" }, " TOTAL ")
                            ], -1)),
                            _createElementVNode("div", _hoisted_28, _toDisplayString(shoppingCart.value.getFormattedTotalPrice()), 1),
                            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "column is-full" }, [
                              _createElementVNode("p", { class: "subtitle is-7 has-text-centered" }, " Caso tenha um cupom de desconto, você deverá inserir o código no checkout. ")
                            ], -1)),
                            _createElementVNode("div", _hoisted_29, [
                              _createElementVNode("button", {
                                type: "button",
                                class: "button is-danger is-fullwidth",
                                onClick: startCheckout
                              }, [
                                _cache[4] || (_cache[4] = _createTextVNode(" Seguir para checkout ")),
                                _createVNode(_component_font_awesome_icon, {
                                  class: "fa-1x ml-2",
                                  icon: { prefix: 'fa', iconName: 'external-link' }
                                })
                              ])
                            ])
                          ])
                        ])
                      ]))
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main has-navbar-fixed-top" }

import {
  ref,
  onBeforeMount,
  onUnmounted,
} from 'vue';
import { useRouter } from 'vue-router';
import useUserStore from '@/store/user';
import PageHeader from '@/components/header/PageHeader.vue';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserProfileManagerLayout',
  setup(__props) {

const router = useRouter();
const {
  usersDoGetMyUser,
  resetStore,
} = useUserStore();

/*
  Data
*/
const isLoading = ref(true);
const myProfile = ref({});

/*
  Methods
*/
const redirectDashboard = ((error: string) => router.push({
  name: 'DashboardPage',
  query: {
    message: 'Não foi possível carregar seu perfil. Tente novamente em instantes.',
    detail: error,
    type: 'error',
  },
}));
const resetFields = async () => {
  isLoading.value = true;
  await usersDoGetMyUser()
    .then((response) => {
      if (response === undefined) {
        redirectDashboard('O perfil que você tento acessar está indisponível no momento. Recarregue a página e tente novamente');
      }
      myProfile.value = response;
      isLoading.value = false;
    }).catch((error) => redirectDashboard(error.toString()));
};

/*
  Hooks
*/
onBeforeMount(async () => {
  await resetFields();
});
onUnmounted(() => {
  resetStore();
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(PageHeader, {
      "is-loading": isLoading.value,
      title: "Minha conta"
    }, null, 8, ["is-loading"]),
    _createElementVNode("main", _hoisted_1, [
      _createVNode(_component_RouterView, {
        modelValue: myProfile.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((myProfile).value = $event)),
        "onUpdate:myProfile": resetFields
      }, null, 8, ["modelValue"])
    ]),
    (isLoading.value)
      ? (_openBlock(), _createBlock(LoadingComponent, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})
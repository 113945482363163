<template>
  <section class="hero is-fullheight">
    <div class="columns m-0 gradient-container">
      <div class="column is-flex is-align-items-center pl-0 pr-0">
        <div class="column is-8 is-offset-2 pt-0">
          <div class="box">
            <div class="block is-flex">
              <RouterLink :to="{ name: 'HomePage' }">
                <SmallLogoComponent />
              </RouterLink>
            </div>
            <div class="block">
              <p class="title has-text-primary">
                Criar uma Conta
              </p>
              <p>
                Utilize seu melhor email, pois enviaremos sua senha de
                primeiro acesso para ele.
              </p>
            </div>
            <hr>
            <form
              class="mt-5"
              @keydown.enter.prevent
              @submit.prevent="submitForm"
            >
              <input
                v-model="documentType"
                type="hidden"
                class="input"
                autocomplete="document-type"
                readonly
              >

              <component
                :is="TextFieldInput"
                v-model="name"
                input-id="name"
                :is-loading="isLoading"
                :input-required="true"
                input-label="Nome Completo"
                input-placeholder="Como consta no seu documento"
                :input-min-length="6"
                :input-min-words="2"
                :input-max-length="256"
                input-min-words-error="Preencha seu nome completo"
                input-auto-complete="name"
                :input-error="nameError"
                @update:input-error="(value) => nameError = value"
              />

              <component
                :is="CPFInput"
                v-model="document"
                input-id="documentId"
                :is-loading="isLoading"
                :input-required="true"
                input-label="CPF"
                input-placeholder="Utilize apenas números"
                :input-error="documentError"
                @update:error="(value) => documentError = value"
              />

              <component
                :is="EmailInput"
                v-model="email"
                input-id="emailId"
                :is-loading="isLoading"
                :input-required="true"
                input-label="Email"
                input-placeholder="Iremos validar seu email"
                :input-error="emailError"
                @update:input-error="(value) => emailError = value"
              />
              <br>
              <component
                :is="TermsAndConditionsInput"
                v-model="acceptTerms"
                :input-error="acceptTermsError"
                input-label="Termos e Condições"
                input-id="terms"
                :is-loading="isLoading"
                :input-placeholder="getTermsAndConditionsLink"
                @update:input-error="(value) => acceptTermsError = value"
              />
              <div class="field has-text-right mt-6">
                <div class="control is-flex is-justify-content-flex-end">
                  <button
                    :class="['box', 'button', 'is-primary', {
                      'is-loading': isLoading,
                    }]"
                    type="submit"
                    :disabled="!submitEnabled || isLoading"
                  >
                    Criar minha conta
                  </button>
                </div>
              </div>
            </form>
            <hr>
            <div class="has-text-centered">
              <p>
                Já tem uma conta? Faça <RouterLink
                  :to="{ name: 'SessionLoginPage' }"
                  class="has-text-primary"
                >
                  Login
                </RouterLink>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          column
          is-flex
          is-align-items-center
          is-justify-content-center"
        style="height: 100vh"
      >
        <div class="columns is-multiline">
          <div class="column is-full">
            <p class="title">
              Maximize o impacto do video e multiplique sua taxa de conversão
            </p>
          </div>
          <div class="column is-full">
            <p class="subtitle">
              <!-- eslint-disable-next-line max-len -->
              Atinja conversões 10x superiores ou mais que seu e-commerce: Descubra o poder da transmissão ao vivo com a Live Link
            </p>
          </div>
          <div class="column is-full">
            <figure class="image is-4by3">
              <img
                alt="Placeholder image"
                src="@/assets/images/explore.svg"
              >
            </figure>
          </div>
        </div>
      </div>
    </div>
  </section>
  <notifications classes="toast-notification" />
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  defineAsyncComponent,
} from 'vue';
import { useRouter } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';

import AuthStore from '@/store/auth';
import useUserCookies from '@/use/useUserCookies';
import TextFieldInput from '@/components/form/TextFieldInput.vue';

const EmailInput = defineAsyncComponent(() => import('@/components/form/EmailInput.vue'));
const TermsAndConditionsInput = defineAsyncComponent(() => import('@/components/form/TermsAndConditionsInput.vue'));
const CPFInput = defineAsyncComponent(() => import('@/components/form/CPFInput.vue'));
const SmallLogoComponent = defineAsyncComponent(() => import('@/components/shared/SmallLogoComponent.vue'));

const router = useRouter();
const { authDoSignup } = AuthStore();

/*
  Data
*/
const documentType = ref('CPF');
const name = ref('');
const nameError = ref('');
const document = ref('');
const documentError = ref('');
const email = ref('');
const emailError = ref('');
const acceptTerms = ref(false);
const acceptTermsError = ref('');
const { getLanguage, getTimezone } = useUserCookies();
const isLoading = ref(false);

/*
  Computed
*/
const getTermsAndConditionsLink = computed(() => ({
  text: 'Li e aceito os',
  linkText: 'Termos e Condições',
  link: '/terms-and-conditions',
  target: '_blank',
}));
const submitEnabled = computed(() => (
  !!document.value
  && !documentError.value
  && !!name.value
  && !nameError.value
  && !!email.value
  && !emailError.value
  && !!acceptTerms.value
  && !acceptTermsError.value
));

/*
  Methods
*/
const submitForm = async () => {
  isLoading.value = true;
  authDoSignup(
    await getLanguage(),
    await getTimezone(),
    name.value,
    document.value,
    documentType.value,
    email.value,
  ).then((response) => {
    if (response?.status !== 200) {
      throw new Error('Não foi possível completar sua solicitação. Tente novamente mais tarde.');
    }
    router.push({ name: 'SessionTemporaryPasswordPage' });
  }).catch((error) => {
    let { message } = error;
    if (message === undefined) {
      message = error;
    }
    if (error === undefined) {
      message = 'Não foi possível completar sua solicitação. Tente novamente mais tarde. (x002)';
    }

    notify({
      duration: 5000,
      type: 'error',
      title: 'Ooops',
      text: message,
    });
    isLoading.value = false;
  });
};
</script>

<style scoped lang="scss">
.gradient-container {
  width: 100%;
  background: linear-gradient(0.35turn, #00d1b2 5%, #f0f4f7 70%);
}
</style>
